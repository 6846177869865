import React, { Component } from "react";
import myApp from "../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import firebase from "firebase/compat/app";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Title from "../../shared/components/title";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import logo from "../../res/images/dashboard_logo.png";
import theme from "../../core/theme.json";
import routerHistory from "../../core/infrastructure/routerHistory";
import { sendMailToNewUser } from "../functions/sendMail";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
} from "@mui/material";
import { deleteField } from "firebase/firestore";
import { getAuth, deleteUser } from "firebase/auth";
import { getUserInformation } from "../../dashboard/command/getUserInformation";
import Swal from "sweetalert2";
import { getPermitions } from "../../dashboard/presentation/componentList";

const encryptPassword = myApp.functions().httpsCallable("encryptPassword");
const decryptPassword = myApp.functions().httpsCallable("decryptPassword");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

class UserControl extends Component {
  constructor(props) {
    let auth = getAuth();

    super(props);

    this.state = {
      users: [],
      companyGroup: localStorage.getItem("@blue-legal/companyGroup"),
      tabIndex: 0,
      permissionModal: false,
      permissionName: "",
      selectedPermissions: {
        insert: false,
        update: false,
        delete: false,
        vizuali: false,
      },
      profiles: [],
      listPagePermition: [],
      PagePermition: [],
      database: myApp.firestore(),
      userName: "",
      email: "",
      password: "",
      insertCheckbox: false,
      editCheckbox: false,
      deleteCheckbox: false,
      selectedProfile: 0,
      selectedUserProfile: -1,
      editPermissionModal: false,
      openAddUserDialog: false,
      openViewUserDialog: false,
      openEditUserDialog: false,
      saveUserButtonDisabled: true,
      selectedUser: -1,
      saveProfileButtonDisabled: true,
      loading: true,
      errorEmail: false,
      errorEmailMessage: "",
      loadingUserDialog: false,
      resetPasswordCheckbox: false,
      loadingRemove: false,
      PageList: [],
    };
  }

  async componentDidMount() {
    await this.getUsers();
    await this.getProfiles();
    await this.handlePermitions();

    this.setState({
      loading: false,
    });
  }
  async handlePermitions() {
    const Permitions = await getPermitions();

    this.setState({
      PageList: Permitions,
    });
  }

  async getProfiles() {
    let profiles = [];

    try {
      let profilesData = await this.state.database
        .collection("profiles_company_group")
        .where("fk_company_group", "==", this.state.companyGroup)
        .get();

      for (const doc of profilesData.docs) {
        let profile = doc.data();
        profile["id"] = doc.id;
        profile["PagePermition"] = doc.data().PagePermition;
        profiles.push(profile);
      }
    } catch (e) {
      console.error("Error getting profiles", e);
    }

    this.setState({
      profiles,
    });
    this.setState({
      PagePermition: profiles?.map((item) => item.PagePermition)[0],
    });

    console.log("profiles", this.state.profiles[2]);
  }

  async getUsers() {
    let users = [];

    try {
      let usersData = await this.state.database
        .collection("users")
        .where("fk_company_group", "==", this.state.companyGroup)
        .where("isAdmin", "==", false)
        .get();

      for (const doc of usersData.docs) {
        let user = doc.data();
        user["id"] = doc.id;
        users.push(user);
      }
    } catch (e) {
      console.error("Error getting users", e);
    }
    console.log(users);
    this.setState({
      users,
    });
  }

  handleUserName(value) {
    this.setState(
      {
        userName: value,
      },
      () => this.handleSaveUserButton()
    );
  }

  handleEmail(value) {
    this.setState(
      {
        email: value,
        errorEmail: false,
        errorEmailMessage: "",
      },
      () => this.handleSaveUserButton()
    );
  }

  handlePassword(value) {
    this.setState(
      {
        password: value,
      },
      () => this.handleSaveUserButton()
    );
  }

  handleSelectUserProfile(index) {
    console.log(index);
    this.setState(
      {
        selectedUserProfile: index,
      },
      () => this.handleSaveUserButton()
    );
  }

  handleResetPasswordCheckbox(event) {
    this.setState({
      resetPasswordCheckbox: event.target.checked,
    });
  }

  handleSaveUserButton() {
    if (this.state.openAddUserDialog) {
      if (
        this.state.userName !== "" &&
        this.state.email !== "" &&
        this.state.password !== "" &&
        this.state.selectedUserProfile !== -1
      ) {
        this.setState({
          saveUserButtonDisabled: false,
        });
      } else {
        this.setState({
          saveUserButtonDisabled: true,
        });
      }
    } else {
      if (this.state.userName !== "" && this.state.selectedUserProfile !== -1) {
        this.setState({
          saveUserButtonDisabled: false,
        });
      } else {
        this.setState({
          saveUserButtonDisabled: true,
        });
      }
    }
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({
      tabIndex,
    });
  };

  loadUserData(index) {
    const user = this.state.users[index];
    let aux = user.PagePermition;
    let selectedProfileName = "";
    let selectedUserProfile = -1;

    for (const [i, p] of this.state.profiles.entries()) {
      if (p.id === user.fk_profile) {
        selectedProfileName = p.name;
        selectedUserProfile = i;
      }
    }
    this.setState({
      userName: user.displayName,
      email: user.email,
      selectedUserProfile,
      selectedProfileName,
      selectedUser: index,
      resetPasswordCheckbox: user.resetPassword,
      PagePermition: aux ?? null,
    });
  }

  handleOpenAddUserDialog() {
    this.setState({
      openAddUserDialog: true,
    });
  }

  handleCloseAddUserDialog() {
    this.setState({
      openAddUserDialog: false,
      userName: "",
      email: "",
      password: "",
      selectedUserProfile: -1,
      resetPasswordCheckbox: false,
      saveUserButtonDisabled: true,
      loadingUserDialog: false,
    });
  }

  handleOpenViewUserDialog(index) {
    this.loadUserData(index);
    this.setState({
      openViewUserDialog: true,
    });
  }

  handleCloseViewUserDialog() {
    this.setState({
      openViewUserDialog: false,
      userName: "",
      email: "",
      selectedUserProfile: -1,
      resetPasswordCheckbox: false,
    });
  }

  handleOpenEditUserDialog(index) {
    this.loadUserData(index);
    this.setState({
      openEditUserDialog: true,
      saveUserButtonDisabled: false,
    });
  }

  handleCloseEditUserDialog() {
    this.setState({
      openEditUserDialog: false,
      userName: "",
      email: "",
      selectedUserProfile: -1,
      resetPasswordCheckbox: false,
      saveUserButtonDisabled: true,
      loadingUserDialog: false,
    });
  }
  async getPermitions() {
    const infoUser = await getUserInformation();
  }

  removeObjOffArray(item, index) {
    let indexOfObj = index;
    let name = item.name;
    let aux = this.state.listPagePermition;
    // aux.map((item ,index) => console.log("index", index) )

    this.state.listPagePermition.findIndex((item, index) =>
      item.index == indexOfObj ? aux.splice(index, 1) : console.log("nao tem")
    );
  }

  addObjOffArray(item, index, e) {
    let aux = [];
    aux.push(...this.state.listPagePermition, {
      page: item.name,
      status: e.target.checked,
      index: index,
    });
    this.setState({
      listPagePermition: aux,
    });
  }

  removeObjOffArrayEdit(item, index) {
    let indexOfObj = index;
    let aux = this.state.PagePermition;
    // aux.map((item ,index) => console.log("index", index) )

    this.state.PagePermition.findIndex((item, index) =>
      item.index == indexOfObj ? aux.splice(index, 1) : console.log("nao tem")
    );
  }

  addObjOffArrayEdit(item, index, e) {
    let aux = this.state.PagePermition ?? [];
    aux.push({
      page: item.name,
      status: e.target.checked,
      index: index,
    });
    this.setState({
      PagePermition: aux,
    });
  }

  async addUser() {
    let thisUserisAdmin = false;
    if (
      this.state.profiles[this.state.selectedUserProfile].permissions.delete ===
        true &&
      this.state.profiles[this.state.selectedUserProfile].permissions.insert ===
        true &&
      this.state.profiles[this.state.selectedUserProfile].permissions.update ===
        true
    ) {
      thisUserisAdmin = true;
    }

    let users = this.state.users;
    const validaEmail =
      this.state.email !== "" &&
      this.state.email.includes(".") &&
      this.state.email.includes("@");

    if (validaEmail) {
      try {
        const usersSnapshot = await this.state.database
          .collection("users")
          .where("email", "==", this.state.email)
          .get();

        if (usersSnapshot.empty) {
          this.setState({
            loadingUserDialog: true,
          });

          const secondaryApp = firebase.initializeApp(config, "Secondary");
          const userCredential = await secondaryApp
            .auth()
            .createUserWithEmailAndPassword(
              this.state.email,
              this.state.password
            );

          await userCredential.user.updateProfile({
            displayName: this.state.userName,
          });

          const encryptResponse = await encryptPassword(this.state.password);

          let user = {
            displayName: this.state.userName,
            email: userCredential.user.email,
            // photoURL: userCredential.user.photoURL,
            photoURL: localStorage.getItem("userPhoto"),
            uid: userCredential.user.uid,
            password: encryptResponse.data.encryptedPass,
            isAdmin: thisUserisAdmin,
            resetPassword: this.state.resetPasswordCheckbox,
            requireOldPassword: this.state.resetPasswordCheckbox,
            fk_profile: this.state.profiles[this.state.selectedUserProfile].id,
            fk_company_group: this.state.companyGroup,
            permitionLevel:
              this.state.profiles[this.state.selectedUserProfile] ?? {},
          };

          await this.state.database.collection("users").add(user);

          users.push(user);

          this.setState({
            users,
          });

          secondaryApp.auth().signOut();

          await sendMailToNewUser(
            this.state.userName,
            this.state.email,
            this.state.password
          );

          this.handleCloseAddUserDialog();
        } else {
          this.setState({
            errorEmail: true,
            errorEmailMessage: "O e-mail informado já existe",
          });
        }
      } catch (error) {
        console.error("Error creating user: ", error);
      }
    } else {
      this.setState({
        errorEmail: true,
        errorEmailMessage: "Digite um e-mail válido",
      });
    }
  }

  async updateUser() {
    let thisUserisAdmin = false;
    if (
      this.state.profiles[this.state.selectedUserProfile].permissions.delete ===
        true &&
      this.state.profiles[this.state.selectedUserProfile].permissions.insert ===
        true &&
      this.state.profiles[this.state.selectedUserProfile].permissions.update ===
        true
    ) {
      thisUserisAdmin = true;
    }

    this.setState({
      loadingUserDialog: true,
    });

    let users = this.state.users;
    let user = this.state.users[this.state.selectedUser];
    user.displayName = this.state.userName;
    user.fk_profile = this.state.profiles[this.state.selectedUserProfile].id;
    user.resetPassword = this.state.resetPasswordCheckbox;
    user.requireOldPassword = this.state.resetPasswordCheckbox;
    users[this.state.selectedUser] = user;

    try {
      const decryptResponse = await decryptPassword(user.password);

      const secondaryApp = firebase.initializeApp(config, "Secondary");
      const userCredential = await secondaryApp
        .auth()
        .signInWithEmailAndPassword(
          user.email,
          decryptResponse.data.decryptedPass
        );

      await userCredential.user.updateProfile({
        displayName: this.state.userName,
      });

      await this.state.database
        .collection("users")
        .doc(user.id)
        .update({
          displayName: user.displayName,
          fk_profile: user.fk_profile,
          resetPassword: user.resetPassword,
          requireOldPassword: user.resetPassword,
          isAdmin: thisUserisAdmin,
          permitions: deleteField(),
          permitions: this.state.PagePermition ?? [],
          permitionLevel:
            this.state.profiles[this.state.selectedUserProfile].PagePermition !=
            undefined
              ? this.state.profiles[this.state.selectedUserProfile]
              : [],
        });
      console.log(this.state.profiles[this.state.selectedUserProfile]);
      this.setState({
        users,
      });

      secondaryApp.auth().signOut();
    } catch (error) {
      console.error("Error updating user: ", error);
    }

    this.handleCloseEditUserDialog();
    this.getPermitions();
  }

  async addProfile() {
    let permissions = this.state.selectedPermissions;

    try {
      const newDocRef = await this.state.database
        .collection("profiles_company_group")
        .add({
          fk_company_group: this.state.companyGroup,
          name: this.state.permissionName,
          permissions,
          PagePermition: this.state.listPagePermition,
        });

      let profiles = this.state.profiles;
      let profile = {
        id: newDocRef.id,
        fk_company_group: this.state.companyGroup,
        name: this.state.permissionName,
        permissions,
      };
      profiles.push(profile);

      this.setState({
        profiles,
      });
    } catch (e) {
      console.error("Error adding profile: ", e);
    }
    this.getProfiles();
    this.handleClose();
  }

  async updateProfile() {
    let index = this.state.selectedProfile;
    let profiles = this.state.profiles;
    let profile = this.state.profiles[index];
    profile.name = this.state.permissionName;
    profile.permissions = this.state.selectedPermissions;
    profiles[index] = profile;

    try {
      await this.state.database
        .collection("profiles_company_group")
        .doc(profile.id)
        .update({
          name: profile.name,
          permissions: profile.permissions,
          PagePermition: deleteField(),
          PagePermition: this.state.PagePermition ?? [],
        });

      this.setState({
        profiles,
      });
    } catch (e) {
      console.error("Error updating profile: ", e);
    }
    this.getProfiles();
    this.handleClose();
  }

  handleOpen() {
    if (this.state.tabIndex === 1) {
      this.setState({
        permissionModal: true,
      });
    } else {
      this.setState({
        openAddUserDialog: true,
      });
    }
  }

  handleClose() {
    this.setState({
      permissionModal: false,
      editPermissionModal: false,
      permissionName: "",
      selectedPermissions: {
        insert: false,
        update: false,
        delete: false,
      },
      insertCheckbox: false,
      editCheckbox: false,
      deleteCheckbox: false,
      saveProfileButtonDisabled: true,
    });
  }

  handleCloseRemove() {
    this.setState({
      loadingRemove: false,
    });
  }
  handlePermissionName(value) {
    this.setState(
      {
        permissionName: value,
      },
      () => this.handleSaveProfileButton()
    );
  }

  handleInsertCheckbox(event) {
    let aux = this.state.selectedPermissions;

    aux.insert = event.target.checked;

    this.setState(
      {
        selectedPermissions: aux,
        insertCheckbox: event.target.checked,
      },
      () => this.handleSaveProfileButton()
    );
  }

  handleEditCheckbox(event) {
    let aux = this.state.selectedPermissions;

    aux.update = event.target.checked;

    this.setState(
      {
        selectedPermissions: aux,
        editCheckbox: event.target.checked,
      },
      () => this.handleSaveProfileButton()
    );
  }

  handleDeleteCheckbox(event) {
    let aux = this.state.selectedPermissions;

    aux.delete = event.target.checked;

    this.setState(
      {
        selectedPermissions: aux,
        deleteCheckbox: event.target.checked,
      },
      () => this.handleSaveProfileButton()
    );
  }

  handleSaveProfileButton() {
    if (
      this.state.permissionName !== "" &&
      (this.state.insertCheckbox ||
        this.state.editCheckbox ||
        this.state.deleteCheckbox)
    ) {
      this.setState({
        saveProfileButtonDisabled: false,
      });
    } else {
      this.setState({
        saveProfileButtonDisabled: true,
      });
    }
  }

  handleEditProfileDialog = (index) => {
    let profile = this.state.profiles[index];
    let name = profile.name;
    let permissions = profile.permissions;

    this.setState({
      editPermissionModal: true,
      permissionName: name,
      PagePermition: profile.PagePermition,
      insertCheckbox: permissions.insert,
      editCheckbox: permissions.update,
      deleteCheckbox: permissions.delete,
      selectedPermissions: {
        insert: permissions.insert,
        update: permissions.update,
        delete: permissions.delete,
      },
      selectedProfile: index,
      saveProfileButtonDisabled: false,
    });
  };

  async removeProfille(profille, index) {
    this.setState({
      loadingRemove: true,
    });
    const data = await myApp
      .firestore()
      .collection("profiles_company_group")
      .where("name", "==", profille.name)
      .where("fk_company_group", "==", profille.fk_company_group)
      .get();

    for (let item of data.docs) {
      let id = item.id;
      try {
        const remove = await myApp
          .firestore()
          .collection("profiles_company_group")
          .doc(id)
          .delete();

        await this.getProfiles();

        Swal.fire({
          icon: "success",
          title: "Perfil removido com sucesso!",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({
          loadingRemove: false,
        });
      }
    }
  }


  async removeUserAccount(user) {

    getAuth()
  .deleteUser(user.uid)
  .then(() => {
    console.log('Successfully deleted user');
  })
  .catch((error) => {
    console.log('Error deleting user:', error);
  });


    // let get = await myApp
    //   .firestore()
    //   .collection("users")
    //   .where("fk_company_group", "==", user.fk_company_group)
    //   .where("email", "==", user.email)
    //   .get();

    // for (let item of get.docs) {
    //   console.log(item.id);
    //   let get = await myApp
    //     .firestore()
    //     .collection("users")
    //     .doc(item.id)
    //     .delete()
    //     .then(async () => {
    //       await this.getUsers();
    //       Swal.fire({
    //         icon: "success",
    //         title: "Usuário removido com sucesso!",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //     });
    // }
  }
  // handleRules(permissions) {
  //   if (permissions) {
  //     let rules = "";

  //     if (permissions.update) {
  //       rules += ", Editar";
  //     }

  //     if (permissions.insert) {
  //       rules += ", Inserir";
  //     }

  //     if (permissions.delete) {
  //       rules += ", Deletar";
  //     }
  //     if (permissions.vizuali) {
  //       rules += ", Visualizar";
  //     }
  //     if (
  //       !permissions.insert &&
  //       !permissions.update &&
  //       !permissions.delete &&
  //       !permissions.vizuali
  //     ) {
  //       return "Sem permissão";
  //     } else {
  //       return rules.slice(2);
  //     }
  //   }
  // }

  goToHome() {
    routerHistory.push("/company");
  }

  render() {
    return (
      <>
        <AppBar
          position="fixed"
          className="appBar"
          style={{ background: theme.secondary_color }}
          elevation={0}
        >
          <Toolbar style={{ padding: "0px" }}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                {/* <img
                  alt="logo"
                  src={logo}
                  style={{ height: "42px", paddingTop: "4px" }}
                /> */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            placeContent: "center",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <Grid style={{ width: "95%" }}>
            <div style={{ marginTop: "5%" }}>
              <Button
                variant="outlined"
                onClick={() => this.goToHome()}
                style={{
                  background: theme.primary_color,
                  color: theme.secondary_color,
                  fontSize: theme.font_button,
                  borderColor: theme.secondary_color,
                }}
              >
                Voltar
              </Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Title title="Controle de usuários" style={{ color: "black" }} />
            </div>
            {this.state.loading ? (
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: "100%", height: "100%" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  style={{ paddingTop: 10 }}
                >
                  <CircularProgress size={70} color={theme.secondary_color} />
                </Grid>
              </Grid>
            ) : (
              <Paper>
                <div style={{ marginTop: "3%" }}>
                  <TabContext value={this.state.tabIndex}>
                    <TabList
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                    >
                      <Tab label="Usuários" value={0} wrapped />
                      <Tab label="Permissões" value={1} wrapped />
                      <Tooltip title="Adicionar usuário">
                        <IconButton onClick={() => this.handleOpen()}>
                          <AddIcon style={{ color: theme.secondary_color }} />
                        </IconButton>
                      </Tooltip>
                    </TabList>
                    <TabPanel value={0} style={{ padding: 0 }}>
                      <TableContainer
                        component={Paper}
                        style={{ maxHeight: "70vh" }}
                      >
                        <Table stickyHeader>
                          <TableBody>
                            {this.state.users.map((user, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <small style={{ color: "#A0A4B0" }}>
                                    Nome:
                                  </small>
                                  <Typography>{user.displayName}</Typography>
                                </TableCell>
                                <TableCell>
                                  <small style={{ color: "#A0A4B0" }}>
                                    Email:
                                  </small>
                                  <Typography>{user.email}</Typography>
                                </TableCell>
                                <TableCell>
                                  <small style={{ color: "#A0A4B0" }}>
                                    Permissão:
                                  </small>
                                  <Typography>
                                    {/* {
                                      this.state.profiles.find(
                                        (p) => p.id === user.fk_profile
                                      ).name
                                    } */}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Visualizar">
                                    <Button
                                      onClick={(e) =>
                                        this.handleOpenViewUserDialog(index)
                                      }
                                      variant="outlined"
                                      style={{
                                        color: theme.secondary_color,
                                        backgroundColor: theme.primary_color,
                                        borderColor: theme.secondary_color,
                                        width: "35%",
                                        left: "10%",
                                      }}
                                    >
                                      <VisibilityIcon
                                        style={{ color: "white" }}
                                      />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Editar">
                                    <Button
                                      onClick={(e) =>
                                        this.handleOpenEditUserDialog(index)
                                      }
                                      variant="outlined"
                                      style={{
                                        color: theme.secondary_color,
                                        backgroundColor: theme.primary_color,
                                        borderColor: theme.secondary_color,
                                        width: "35%",
                                      }}
                                    >
                                      <EditIcon style={{ color: "white" }} />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                  <Tooltip title="Remover">
                                    <Button
                                      onClick={(e) =>
                                        this.removeUserAccount(user)
                                      }
                                      variant="outlined"
                                      style={{
                                        color: theme.secondary_color,
                                        backgroundColor: theme.warning_color,
                                        borderColor: theme.secondary_color,
                                        width: "35%",
                                      }}
                                    >
                                      <DeleteIcon style={{ color: "white" }} />
                                    </Button>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={1} style={{ padding: 0 }}>
                      <TableContainer
                        component={Paper}
                        style={{ maxHeight: "70vh" }}
                      >
                        <Table stickyHeader>
                          <TableBody>
                            {this.state.profiles.map((profile, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <small style={{ color: "#A0A4B0" }}>
                                      Perfil:
                                    </small>
                                    <Typography>{profile.name}</Typography>
                                  </TableCell>

                                  <TableCell>
                                    <Tooltip title="Editar">
                                      <Button
                                        onClick={(e) =>
                                          this.handleEditProfileDialog(index)
                                        }
                                        variant="outlined"
                                        style={{
                                          color: theme.secondary_color,
                                          backgroundColor: theme.primary_color,
                                          borderColor: theme.secondary_color,
                                          width: "20%",
                                        }}
                                      >
                                        <EditIcon style={{ color: "white" }} />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Remover">
                                      <Button
                                        onClick={(e) =>
                                          this.removeProfille(profile, index)
                                        }
                                        variant="outlined"
                                        style={{
                                          color: theme.secondary_color,
                                          backgroundColor: theme.warning_color,
                                          borderColor: theme.secondary_color,
                                          width: "20%",
                                          marginLeft: "5%",
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{ color: "white" }}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabContext>
                </div>
              </Paper>
            )}
          </Grid>
        </div>

        {/* create user */}
        <Dialog
          open={this.state.openAddUserDialog}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleCloseAddUserDialog()}
        >
          <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    position: "absolute",
                    left: "220px",
                  }}
                >
                  Adicionar usuário
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleCloseAddUserDialog()}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {this.state.loadingUserDialog ? (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ width: "100%", height: "100%" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ paddingTop: 10 }}
                >
                  <CircularProgress size={40} color={theme.secondary_color} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    value={this.state.userName}
                    onChange={(e) => this.handleUserName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={this.state.email}
                    onChange={(e) => this.handleEmail(e.target.value)}
                    error={this.state.errorEmail}
                    helperText={
                      this.state.errorEmail ? this.state.errorEmailMessage : ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label="Senha"
                    variant="outlined"
                    fullWidth
                    value={this.state.password}
                    onChange={(e) => this.handlePassword(e.target.value)}
                  />
                </Grid>

                <Grid
                  style={{
                    width: "98%",
                    padding: "2% 0 2% 0",
                    whiteSpace: "normal",
                  }}
                ></Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Nível</InputLabel>
                    <Select
                      value={this.state.selectedUserProfile}
                      onChange={(event) =>
                        this.handleSelectUserProfile(event.target.value)
                      }
                      label="Nível"
                    >
                      <MenuItem key={-1} value={-1}>
                        Selecione
                      </MenuItem>
                      {this.state.profiles.map((perm, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {perm.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Redefinir senha"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={this.state.resetPasswordCheckbox}
                        onChange={(event) =>
                          this.handleResetPasswordCheckbox(event)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => this.addUser()}
                    style={{
                      color: theme.secondary_color,
                      backgroundColor: this.state.saveUserButtonDisabled
                        ? theme.opacity
                        : theme.primary_color,
                      borderColor: theme.secondary_color,
                    }}
                    disabled={this.state.saveUserButtonDisabled}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => this.handleCloseAddUserDialog()}
                    style={{
                      color: theme.secondary_color,
                      backgroundColor: "white",
                      borderColor: theme.secondary_color,
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>

        {/* view user dialog */}
        <Dialog
          open={this.state.openViewUserDialog}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleCloseViewUserDialog()}
        >
          <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    position: "absolute",
                    left: "220px",
                  }}
                >
                  Visualizar usuário
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleCloseViewUserDialog()}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  value={this.state.userName}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={this.state.email}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth disabled>
                  <InputLabel>Permissões</InputLabel>
                  <Select label="Permissões" value={0}>
                    <MenuItem key={0} value={0}>
                      {this.state.selectedProfileName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Redefinir senha"
                  labelPlacement="end"
                  control={
                    <Checkbox
                      disabled
                      checked={this.state.resetPasswordCheckbox}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => this.handleCloseViewUserDialog()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: "white",
                    borderColor: theme.secondary_color,
                  }}
                >
                  Fechar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* dialog edit user */}
        <Dialog
          open={this.state.openEditUserDialog}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleCloseEditUserDialog()}
        >
          <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    position: "absolute",
                    left: "220px",
                  }}
                >
                  Editar usuário
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleCloseEditUserDialog()}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {this.state.loadingUserDialog ? (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ width: "100%", height: "100%" }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ paddingTop: 10 }}
                >
                  <CircularProgress size={40} color={theme.secondary_color} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => this.handleUserName(e.target.value)}
                    value={this.state.userName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={this.state.email}
                    disabled
                  />

                  <Grid
                    style={{
                      width: "98%",
                      padding: "2% 0 2% 0",
                      whiteSpace: "normal",
                    }}
                  ></Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Permissões</InputLabel>
                    <Select
                      value={this.state.selectedUserProfile}
                      onChange={(event) =>
                        this.handleSelectUserProfile(event.target.value)
                      }
                      label="Permissões"
                    >
                      <MenuItem key={-1} value={-1}>
                        Selecione
                      </MenuItem>
                      {this.state.profiles.map((perm, index) => (
                        <MenuItem key={index} value={index}>
                          {perm.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Redefinir senha"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={this.state.resetPasswordCheckbox}
                        onChange={(event) =>
                          this.handleResetPasswordCheckbox(event)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    // onClick={()=> console.log('page', this.state.PagePermition)}
                    onClick={() => this.updateUser()}
                    style={{
                      color: theme.secondary_color,
                      backgroundColor: this.state.saveUserButtonDisabled
                        ? theme.opacity
                        : theme.primary_color,
                      borderColor: theme.secondary_color,
                    }}
                    disabled={this.state.saveUserButtonDisabled}
                  >
                    Salvar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => this.handleCloseEditUserDialog()}
                    style={{
                      color: theme.secondary_color,
                      backgroundColor: "white",
                      borderColor: theme.secondary_color,
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>

        {/* create permission */}
        <Dialog
          open={this.state.permissionModal}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleClose()}
        >
          <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    position: "absolute",
                    left: "220px",
                  }}
                >
                  Adicionar permissão
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleClose()}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  label="Nome da permissão"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={this.state.permissionName}
                  onChange={(e) => this.handlePermissionName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography style={{ fontWeight: "bold" }}>
                    Permissões
                  </Typography>

                  <Accordion square>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Permissões para páginas</Typography>
                    </AccordionSummary>
                    <Grid>
                      <AccordionDetails
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {this.state.PageList.map((item, index) => {
                          return (
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>{item.name}</Typography>
                              <Switch
                                color="primary"
                                onChange={(e) =>
                                  e.target.checked == false
                                    ? this.removeObjOffArray(item, index)
                                    : this.addObjOffArray(item, index, e)
                                }
                              />
                            </Grid>
                          );
                        })}
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        ></Grid>
                      </AccordionDetails>
                    </Grid>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => this.addProfile()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: this.state.saveProfileButtonDisabled
                      ? theme.opacity
                      : theme.primary_color,
                    borderColor: theme.secondary_color,
                  }}
                  // disabled={this.state.listPagePermition.length > 0}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => this.handleClose()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: "white",
                    borderColor: theme.secondary_color,
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* edit permission dialog*/}
        <Dialog
          open={this.state.editPermissionModal}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          onClose={() => this.handleClose()}
        >
          <DialogTitle style={{ backgroundColor: theme.secondary_color }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    position: "absolute",
                    left: "220px",
                  }}
                >
                  Editar permissão
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => this.handleClose()}>
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  label="Nome da permissão"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(event) =>
                    this.handlePermissionName(event.target.value)
                  }
                  value={this.state.permissionName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography style={{ fontWeight: "bold" }}>
                    Permissões
                  </Typography>

                  <Accordion square>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Permissões para páginas</Typography>
                    </AccordionSummary>
                    <Grid>
                      <AccordionDetails
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {this.state.PageList?.map((item, index) => {
                          return (
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography>{item.name}</Typography>
                              <Switch
                                defaultChecked={
                                  this.state.PagePermition?.find(
                                    (item) => index == item.index
                                  )
                                    ? true
                                    : false
                                }
                                color="primary"
                                onChange={(e) =>
                                  e.target.checked == false
                                    ? this.removeObjOffArrayEdit(item, index)
                                    : this.addObjOffArrayEdit(item, index, e)
                                }
                              />
                            </Grid>
                          );
                        })}
                      </AccordionDetails>
                    </Grid>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => this.updateProfile()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: this.state.saveProfileButtonDisabled
                      ? theme.opacity
                      : theme.primary_color,
                    borderColor: theme.secondary_color,
                  }}
                  disabled={this.state.saveProfileButtonDisabled}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => this.handleClose()}
                  style={{
                    color: theme.secondary_color,
                    backgroundColor: "white",
                    borderColor: theme.secondary_color,
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default UserControl;
