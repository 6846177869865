import React, { Component } from "react";
import {
  Typography,
  Grid,
  Button,
  Select,
  TextField,
  CssBaseline,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Container,
  CardActions,
  CardHeader,
  Zoom,
  Box,
  InputLabel,
  ButtonBase,
  Paper,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CheckCircle, DeleteForever, Error } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { withRouter } from "react-router";
import validateCPF from "../../../shared/commands/validations/validateCPF";
import InputMask from "react-input-mask";
import dashboardLogo from "../../../res/images/dashboard_logo.png";
import { addDays } from "../../../shared/commands/dateFunctions";
import { formatDate } from "../../../shared/commands/formatDate";
import InputFile from "../../../shared/components/InputFile";
import SendVerificationEmailButton from "../../components/SendVerificationEmailButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import Swal from "sweetalert2";
import Log from "../../../shared/commands/log";
import AWSS3UploadAsh from "aws-s3-upload-ash";
import theme from '../../../core/theme.json'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const TextMaskCustomCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
    />
  );
};

const TextMaskCustomPhone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[
        "(",
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
};

const sendSoliciationEmails = myApp
  .functions()
  .httpsCallable("sendSoliciationEmailsCompany");

const styles = (theme) => ({
  headerText: {
    textAlign: "justify",
  },
  footerText: {
    textAlign: "center",
  },
});

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  accessKeyId: process.env.REACT_APP_AWS_ACESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  s3Url: process.env.REACT_APP_AWS_LINK_PRIVACY,
  region: process.env.REACT_APP_AWS_REGION,
};

const S3CustomClient = new AWSS3UploadAsh(config);


class DenunceFormCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      database: myApp.firestore(),
      storage: myApp.storage(),
      solicitationType: "",
      companyId: "",
      companySize: "",
      name: "",
      denuncedate: [],
      dateparams: [],
      sucssees: false,
      lastName: "",
      email: "",
      document: "",
      document_proof_file: { type: "", name: "" },
      list_document: [],
      status: "",
      companies: [],
      companiName: "",
      loading: false,
      loadingFiles: false,
      loadingForm: true,
    };
  }

  async componentDidMount() {
    await this.getSolicitationTypes();
    await this.loadCompanyGroup();
    await this.loadCompanyImage();
    await this.getDenunceParams();
    this.setState({
      loadingForm: false,
    });
  }
  async getDenunceParams() {
    try {
      let getDenunceParams = await this.state.database
        .collection("denunceParameters")
        .where(
          "fk_company_group",
          "==",
          this.props.match.params.companyGroupIdc
        )
        .get();
      let paramsData = getDenunceParams.docs.map((item) => item.data());

      this.setState({
        dateparams: paramsData,
      });

      console.log('items pegos', this.state.dateparams)
    } catch (err) {
      console.log("nao deu pra pegar os dados", err);
    }
  }


  async getSolicitationTypes() {
    let solicitationTypes = this.state.solicitationTypes;
    try {
      let solicitationTypesData = await this.state.database
        .collection("solicitationTypes")
        .where("in_company", "==", this.props.match.params.companyGroupIdc)
        .get();

      for (let doc of solicitationTypesData.docs) {
        let solicitationType = {
          id: doc.id,
          type: doc.data().type,
        };

        solicitationTypes.push(solicitationType);
      }
    } catch (error) {
      console.log("Error loading solicitation types: " + error);
    }

    this.setState({
      solicitationTypes,
    });
  }

  async loadCompanyGroup() {
    console.log(this.props.match.params.companyGroupIdc)
    try {
      const companyGroupSolicitationSnapshot = await this.state.database
        .collection("solicitationParameters")
        .where(
          "fk_company_group",
          "==",
          this.props.match.params.companyGroupIdc
        )
        .get();
             console.log( companyGroupSolicitationSnapshot.docs.map(item => item)) 
      const parametersData = companyGroupSolicitationSnapshot.docs
        .shift()
        .data();
      this.setState({
        companyGroupData: parametersData,
      });
    } catch (error) {
      console.error("Error loading company group data: " + error);
    }
  }

  async loadCompanyImage() {
    try {
      const imageRef = this.state.storage.refFromURL(
        this.state.companyGroupData.logoUrl
      );
      const imageUrl = await imageRef.getDownloadURL();

      this.setState({
        companyImageUrl: imageUrl,
      });
    } catch (error) {
      console.error("Error getting company group logo: " + error);
    }
  }

  handlePersonType(event) {
    this.setState(
      {
        personType: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleSolicitationType(event) {
    this.setState(
      {
        solicitationType: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleCompany(event) {
    this.setState(
      {
        companyId: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleName(event) {
    this.setState(
      {
        name: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleLastName(event) {
    this.setState(
      {
        lastName: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleEmail(event) {
    this.setState(
      {
        email: event.target.value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleDocument(event) {
    this.setState(
      {
        document: event.target.value,
        validDocument: true,
      },
      () => {
        this.handleSendButton();
      }
    );

    let doc = event.target.value;
    doc = doc.replace(/\D/g, "");

    if (doc && doc !== "" && !validateCPF(event.target.value)) {
      this.setState(
        {
          validDocument: false,
        },
        () => this.handleSendButton()
      );
    }
  }

  handlePhone(event) {
    this.setState({
      phone: event.target.value,
    });
  }

  handleAdditionalInformation(event) {
    this.setState({
      additionalInformation: event.target.value,
    });
  }

  handleDocumentProofFile(file) {
    this.setState({
      document_proof_file: file,
    });
  }

  handleBirthDate(value) {
    this.setState(
      {
        birthDate: value,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleConsent() {
    this.setState(
      {
        consentAccepted: !this.state.consentAccepted,
      },
      () => {
        this.handleSendButton();
      }
    );
  }

  handleSendButton() {
    let doc = this.state.document;
    doc = doc.replace(/\D/g, "");

    if (
      this.state.personType !== "" &&
      this.state.solicitationType !== "" &&
      this.state.companyId !== "" &&
      this.state.name !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      //doc !== '' &&
      this.state.birthDate &&
      !isNaN(this.state.birthDate.getTime()) &&
      //this.state.validDocument &&
      this.state.consentAccepted
    ) {
      this.setState({
        sendButtonDisabled: false,
      });
    } else {
      this.setState({
        sendButtonDisabled: true,
      });
    }
  }

  async saveSolicitation() {
    this.setState({
      loading: true,
    });

    let data = new Date(Date.now());
    let year = data.getFullYear().toString();

    let monthInt = data.getMonth() + 1;
    let month = monthInt.toString();
    if (month.length === 1) {
      month = "0" + month;
    }

    let day = data.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }

    let random = Math.floor(Math.random() * 10000000 + 1).toString();

    let protocol = year + month + day + random;

    try {
      const solicitation = await this.state.database
        .collection("solicitations")
        .add({
          protocol: protocol,
          fk_personType: this.state.personType,
          fk_solicitationType: this.state.solicitationType,
          fk_company: this.state.companyId,
          document: this.state.document,
          phone: this.state.phone,
          additionalInformation: this.state.additionalInformation,
          birthDate: this.state.birthDate,
          status: "Aberto",
          done: false,
          response: "",
          createDate: new Date(Date.now()),
          limitDate: addDays(
            Date.now(),
            parseInt(this.state.companyGroupData.expectedDate)
          ),
          conclusionDate: null,
        });



      await sendSoliciationEmails({ solicitation: solicitation.id });

      this.setState({
        loading: false,
        solicitationSent: true,
        errorPage: false,
        errorMessage: "",
        sendingEmailVerification: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        solicitationSent: false,
        errorPage: true,
        errorMessage: error.message,
        sendingEmailVerification: false,
      });
      await Log().error({
        trace: error.trace,
        message: error.message,
        info: {
          state: this.state,
          component: "solicitationForm",
        },
      });
    }
  }


  uploadProgress(upload) {
    return new Promise((resolve, reject) => {
      upload.on(
        "state_changed",
        (snapShot) => {
          this.setState({
            progress: (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
          });
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          resolve();
        }
      );
    });
  }

  async addDenunce() {
    let data = new Date(Date.now());
    let year = data.getFullYear().toString();

    let monthInt = data.getMonth() + 1;
    let month = monthInt.toString();
    if (month.length === 1) {
      month = "0" + month;
    }

    let day = data.getDate().toString();
    if (day.length === 1) {
      day = "0" + day;
    }

    let random = Math.floor(Math.random() * 10000000 + 1).toString();

    let protocol = year + month + day + random;

    try {
      let createDenunce = await myApp
        .firestore()
        .collection("denunce")
        .add({
          protocol: protocol,
          date: Date.now(),
          fk_company: localStorage.getItem("currentCompany"),
          company_relation: this.state.companySize,
          additionalInformation: this.state.additionalInformation
            ? this.state.additionalInformation
            : "",
          textOutros: this.state.outrosText ? this.state.outrosText : "",
          relate_Type: this.state.relateType,
          status: "Aberto",
          responsedenunce: this.state.list_document
        });

      let getDenunceDate = (await createDenunce.get()).data();
      this.setState({
        sucssees: true,
        denuncedate: getDenunceDate,
      });

      console.log("dados", this.state.denuncedate.responsedenunce);
    } catch (err) {
      console.log("nao criado", err);
    }
  }

  async handleFile() {

    this.setState({
      loadingFiles: true
    })
    let aux = [];
    try {
      let response = await S3CustomClient.uploadFile(
        this.state.document_proof_file,
        this.state.document_proof_file.type,
        undefined,
        this.state.document_proof_file.name,
        "public-read"
      );
      aux.push({
        name: response.key,
        url: response.location
      });
      this.setState({
        list_document: aux,
      });

   
      console.log("deu certo", this.state.list_document);
    } catch (error) {
      console.log("erro ao subir img", error);
    }finally{
      this.setState({
        loadingFiles: false
      })
    }
  }

  async deleteDocument(props, index){
  console.log(index)
    try{
    let list = this.state.list_document

      list.splice(index,1)

      this.setState({
        list_document: list
      })
      console.log('teste',list)

    }catch(err){
      console.log(err)
    }
  }

  renderHeaderComponents() {
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item></Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={6}
          >
            <Grid item>
              <img
                alt="companyGroupImg"
                src={
                  this.state.companyImageUrl
                    ? this.state.companyImageUrl
                    : dashboardLogo
                }
                style={{ height: "42px" }}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          this.state.dateparams.map(item => item.privacyPolicyUrl) !== '' &&
          <Grid
        item className={classes.headerText}>
          <Typography variant="body1" align="justify" style={{ width: "100%" }}>

            <br />
            <a
              href={`${this.state.dateparams.map(item => item.privacyPolicyUrl)}`}
              target="_blank"
              rel="noreferrer"
            >
              Política de privacidade
            </a>
          </Typography>
        
        </Grid>}
      </Grid>
    );
  }

  renderBodyComponents() {
    if (this.state.loading) {
      return (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <CircularProgress size={60} color="#193993" />
        </Grid>
      );
    } else if (this.state.sucssees) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Zoom in={this.state.sucssees} timeout={500}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    component="h3"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    Denúncia enviada com sucesso!
                  </Typography>
                }
              />
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    component="h3"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    Número do protocolo :{" "}
                    <span style={{ color: "#4682B4" }}>
                      {" "}
                      {this.state.denuncedate.protocol}{" "}
                    </span>
                  </Typography>
                }
              />
              <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Zoom in={this.state.sucssees} timeout={800}>
                      <CheckCircle
                        style={{ fontSize: 100, color: green[500] }}
                      />
                    </Zoom>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ width: "100%" }}
                >
                  Sua denúncia foi cadastrada com sucesso, para verificar o
                  andamento, basta acessar o link abaixo, clicar em "Acompanhar
                  uma dénuncia existente" e colocar os números abaixo de
                  protocolo, vale lembrar que o tempo de resposta é de{" "}
                  <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {this.state.dateparams?.map((item) =>
                      item.expectedDate
                        ? item.expectedDate + " Dias"
                        : "15 Dias"
                    )}
                  </span>{" "}
                  {/* <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {this.state.dateparams =='' && '15 Dias'}
                  </span> */}
                  .
                </Typography>
              </CardActions>
              <CardActions>
                <Grid container justifyContent="center" alignItems="center">
                  <ButtonBase onClick={() => window.history.back()}>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ width: "100%", color: "#4682B4" }}
                    >
                      Link para acompanhar sua denúncia
                    </Typography>
                  </ButtonBase>
                </Grid>
              </CardActions>
            </Card>
          </Zoom>
        </Grid>
      );
    } else if (this.state.errorPage) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Card>
            <CardContent>
              <Typography
                variant="h2"
                component="h2"
                align="center"
                style={{ width: "100%" }}
              >
                Erro!
              </Typography>
              <p></p>
              <Error style={{ fontSize: 60, color: "#e60000" }} />
              <p></p>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ width: "100%" }}
              >
                {this.state.errorMessage}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="h4"
                  component="h4"
                  align="center"
                  style={{ width: "100%" }}
                >
                  Formulário de denúncia.
                </Typography>
              }
            />
            <CardHeader
              title={
                <Typography
                  variant="body1"
                  align="center"
                  style={{ width: "100%" }}
                >
                  {this.state.dateparams?.map((item) => item.introductoryText)}
                </Typography>
              }
            />

            <CardContent>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  /*if (this.state.document_proof_file === null) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Atenção',
                      text: 'Insira o comprovante do CPF',
                    })
                  } else {
                    this.setState({
                      sendingEmailVerification: true,
                    })
                  }*/
                  this.setState({
                    sendingEmailVerification: true,
                  });
                  return false;
                }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: "100%", padding: "20px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      variant="outlined"
                      id="demo-simple-select-label"
                    >
                      Relação com a empresa
                    </InputLabel>
                    <Select
                      variant="outlined"
                      id="demo-simple-select"
                      label="Relação com a empresa"
                      onChange={(evt) =>
                        this.setState({ companySize: evt.target.value })
                      }
                    >
                      <MenuItem value={"Colaborador"}>Colaborador</MenuItem>
                      <MenuItem value={"Cliente"}>Cliente</MenuItem>
                      <MenuItem value={"Fornecedor"}>Fornecedor</MenuItem>
                      <MenuItem value={"Parceiros"}>Parceiros</MenuItem>
                      <MenuItem value={"Outros"}>Outros</MenuItem>
                    </Select>

                    {this.state.companySize === "Outros" && (
                      <TextField
                        style={{ marginTop: "5%" }}
                        onChange={(evt) =>
                          this.setState({ outrosText: evt.target.value })
                        }
                        id="outlined-basic"
                        label="Diga-nos"
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </Grid>

                {/* Informações Complementares */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                  style={{ width: "100%", padding: "20px" }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ width: "100%" }}
                  >
                    Escreva aqui sua denúncia/irregularidade que deseja relatar
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={this.state.additionalInformation}
                    helperText="Escreva aqui sua denúncia/irregularidade que deseja relatar"
                    onChange={(event) => {
                      this.handleAdditionalInformation(event);
                    }}
                  />

                  <FormControl fullWidth>
                    <InputLabel
                      style={{ marginTop: "5%" }}
                      variant="outlined"
                      id="demo-simple-select-label"
                    >
                      Tipo de relato
                    </InputLabel>
                    <Select
                      style={{ marginTop: "5%" }}
                      variant="outlined"
                      id="demo-simple-select"
                      label="Relação com a empresa"
                      onChange={(evt) =>
                        this.setState({ relateType: evt.target.value })
                      }
                    >
                      <MenuItem value={"Assédio"}>Assédio</MenuItem>
                      <MenuItem value={"Fraude"}>Fraude</MenuItem>
                      <MenuItem value={"Agressão"}>Agressão</MenuItem>
                      <MenuItem value={"Discriminação"}>Discriminação</MenuItem>
                      <MenuItem value={"Violação de lei trabalhista"}>
                        Violação de lei trabalhista
                      </MenuItem>
                      <MenuItem value={"Violação de Lei ambiental"}>
                        Violação de Lei ambiental
                      </MenuItem>
                      <MenuItem value={"Violação de Lei Penal"}>
                        Violação de Lei Penal
                      </MenuItem>
                      <MenuItem value={"Uso indevido de informações"}>
                        Uso indevido de informações
                      </MenuItem>
                      <MenuItem value={"Corrupção ou outros"}>
                        Corrupção ou outros
                      </MenuItem>
                    </Select>
                  </FormControl>

                 {this.state.loadingFiles == false ? <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                    style={{ width: "100%", padding: "20px" }}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ width: "100%" }}
                    >
                      Anexar documento comprobatório .
                    </Typography>
                    <InputFile
                    denunce
                      onAcceptFiles={(files) => {
                        if (files.length)
                          this.handleDocumentProofFile(files[0]);
                      }}
                    />
                    <ButtonBase
                      onClick={() => this.handleFile()}
                      style={{
                        background: theme.primary_color,
                        color: theme.quaternary_color,
                        padding: "15px",
                      }}
                    >
                      
                     adicionar arquivo
                    </ButtonBase>

                    {this.state.list_document.map((item,index) => {
                      return (
                        <Grid item style={{ width: "100%" }}>
                        <Paper
                                      style={{
                                        padding: "8px",
                                        margin: "15px 0",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <DeleteForever 
                                      onClick={()=> this.deleteDocument(item,index)}
                                      style={{ cursor:'pointer'}}/>
                                      <span
                                        style={{
                                          fontSize: "1rem",
                                          color: "#72bcd4",
                                        }}
                                      >
                                        {item.name}
                                      </span>

                                      <ButtonBase
                                        onClick={() => window.open(item.url)}
                                        style={{
                                          background: theme.primary_color,
                                          color: theme.secondary_color,
                                          padding: "5px",
                                        }}
                                      >
                                        {" "}
                                        vizualizar arquivo
                                      </ButtonBase>
                                    </Paper>
                        </Grid>
                      );
                    })}
                  </Grid> :
                   <CircularProgress style={{ marginTop:'10%'}} />
                  }
                </Grid>

                {/* Aceite de consentimento */}
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={3}
                  style={{ width: "100%", padding: "20px" }}
                >
                  <FormControl required component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.consentAccepted}
                            onChange={() => this.handleConsent()}
                            required
                          />
                        }
                        label={this.state.dateparams.map(
                          (item) => item.consentText
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {/* Enviar solicitação */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "100%", padding: "20px" }}
                >
                  <Button
                    variant="contained"
                    disabled={
                      this.state.consentAccepted == false ? true : false
                    }
                    onClick={() => this.addDenunce()}
                    style={
                      this.state.consentAccepted == true
                        ? {
                            background: "#193993",
                            color: "white",
                            fontSize: "22px",
                            fontWeight: 300,
                          }
                        : {
                            background: "#193993",
                            opacity: 0.5,
                            color: "white",
                            fontSize: "22px",
                            fontWeight: 300,
                          }
                    }
                  >
                    Enviar
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      );
    }
  }

  renderFooterComponents() {
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        {/* <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography variant="caption" align="center" noWrap>
                                Powered by
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img alt="logo" src={dashboardLogo} style={{ height: '42px' }} />
                        </Grid>
                    </Grid>
                </Grid> */}
        
      { this.state.dateparams.map((item) =>{
        return(
<Grid item className={classes.footerText}>
                'Caso você não se enquadre nos perfis("Colaborador", "Fornecedor","Cliente" ou "Parceiro"), ou queira exercer algum outro direito que não tenha relação com a nossa Política de Privacidade, entre em contato com a '
          <Typography
            variant="subtitle2"
            align="center"
            style={{ width: "100%" }}
          >
            clique <span onClick={()=>window.open(`${item.serviceChannelUrl}`)}  style={{ cursor:'pointer', color: "#4682B4" }} >aqui</span> para ser redirecinado a central de atendimento
          </Typography>
        </Grid>
        )
      }) }
      </Grid>
    );
  }

  renderLoading() {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: "40vh" }}
        >
          <CircularProgress size={100} color="primary" />
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Container maxWidth="md">
        <CssBaseline />
        {this.state.loadingForm ? (
          this.renderLoading()
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Grid item />
            <Grid item>{this.renderHeaderComponents()}</Grid>
            <Grid item />
            <Grid item>{this.renderBodyComponents()}</Grid>
            <Grid item />
            <Grid item>{this.renderFooterComponents()}</Grid>
            <Grid item />
          </Grid>
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(DenunceFormCompany));
