import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Switch,
  Box,
  MenuItem,
  TextField,
  Typography,
  Zoom,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Paper,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import {
  ArrowBackIosTwoTone,
  DeleteForever,
  Label,
  SaveTwoTone,
} from "@material-ui/icons";

import myApp from "../../../../core/infrastructure/firebaseConfig";
import { connect, useDispatch } from "react-redux";
import { setDenunceObj } from "../redux/ViewsPdfDenunceReducer";
import { changePage } from "../../parameterization/redux/actions";
import { ZoomForm } from "./details/ZoomForm.jsx";
import { ResponseForm } from "./details/ResponseForm";
import { ApurationForm } from "./details/ApurationForm";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import theme from "../../../../core/theme.json";
import SaveApurationDenunceHelper from "./helper/details/SaveApurationDenunce";
import Swal from "sweetalert2";

const DetailsRefatore = ({ solicitationId }) => {
  const dispatch = useDispatch();

  const [denunce, setDenunce] = useState({});
  const [dateParamersDenunce, setDateParamersDenunce] = useState([]);
  const [verificationConclusation, setVerificationConclusation] = useState("");
  const [company_relation_user, setCompany_relation_user] = useState("");
  const [handleStatus, setHandleStatus] = useState("");
  const [handleSensitive, setHandleSensitive] = useState("");
  const [verificationCheckBox, setVerificationCheckBox] = useState(false);
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState("");
  const [confimationDialog, setConfimationDialog] = useState(false);
  const [acept, setAcept] = useState(false);
  const [list_document_Response, setList_document_Response] = useState([]);
  const [handleResponse, setHandleResponse] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const [formResponseFile, setFormResponseFile] = useState({
    type: "",
    name: "",
  });
  const [teste, setTeste] = useState("");

  const [state, SetState] = useState({
    done: false,
  });

  useEffect(() => {
    getDenunceData();
    setTeste("");
  }, []);

  const getDenunceData = async () => {
    try {
      let get = await myApp
        .firestore()
        .collection("denunce")
        .doc(solicitationId)
        .get();

      let denunce = get.data();

      setDenunce(denunce);

      let getDateParamers = await myApp
        .firestore()
        .collection("denunceParameters")
        .where("fk_company_group", "==", localStorage.getItem("currentCompany"))
        .get();
      const dateParamers = getDateParamers.docs.map((item) => item.data());

      setDateParamersDenunce(dateParamers);
    } catch (err) {
      console.log(err);
    }
  };

  const saveApurationDenunce = async () => {
    const teste = await SaveApurationDenunceHelper({
      sensitive: handleSensitive,
      denunce,
      status: handleStatus,
      verificationCheckBox,
      name,
      email,
      cpf,
      company_relation_user,
      verificationConclusation,
      acept,
      response : handleResponse,
      setConfimationDialog,
      setLoadingSend,
    });
    let responseHistory = denunce.responseHistory ?? [];

    try {
      let responseFile = null;
      let internalDocumentsFiles = null;

      // try {
      //   if (this.state.formInternalDocumentsFiles) {
      //     internalDocumentsFiles = await this.uploadInternalDocumentsFile(
      //       solicitationId,
      //       this.state.formInternalDocumentsFiles
      //     );
      //   }
      // } catch (e) {
      //   console.error(e);
      // }

      let response = {
        response: handleResponse ,
        responseDate: new Date(Date.now()),
        status: handleStatus !== "" ? handleStatus : "",
        done: handleStatus == "Concluído" ? true : false,
      };

      const currentUser = JSON.parse(
        sessionStorage.getItem("@blue-legal/userInformation")
      );

      responseHistory.push({
        ...response,
        ...{
          responseFile,
          internalDocumentsFiles,
          userName: currentUser.displayName,
          uid: currentUser.uid,
        },
      });

      let auxfiles = [];

      if (denunce.files_document_response) {
        for (let i of denunce.files_document_response) {
          auxfiles.push(i);
        }
      }
      for (let i of list_document_Response) {
        auxfiles.push(i);
      }

      console.log(auxfiles);
      if(handleResponse != ''){
        await myApp
            .firestore()
            .collection("denunce")
            .doc(solicitationId)
            .update({
        responseHistory,

            })

      }

      denunce.email == null
        ? await myApp
            .firestore()
            .collection("denunce")
            .doc(solicitationId)
            .update({
              done: handleStatus == "Concluído" ? true : false,
              status: handleStatus,
              verificationConclusation,
              email,
              company_user_relation: company_relation_user,
              cpf,
              name,
              Sensitive : handleSensitive,
              files_document_response: auxfiles,
        ...response,

            })
            .then(() => {
              console.log("foi esse");
            })
        : await  myApp
        .firestore()
            .collection("denunce")
            .doc(solicitationId)
            .update({
              done: handleStatus == "Concluído" ? true : false,
              status: handleStatus,
              verificationConclusation,
              Sensitive: denunce.Sensitive,
              files_document_response: auxfiles,
              response:
                denunce.response == undefined || denunce.response == ""
                  ? handleResponse
                  : denunce.response,
              cpf: denunce.Cpf ? denunce.Cpf : cpf,
              name: denunce.name ? denunce.name : name,
              email: denunce.email ? denunce.email : email,
              company_user_relation: denunce.company_relation_user
                ? denunce.company_relation_user
                : company_relation_user,
            });


            setList_document_Response([])
      

      await getDenunceData();

      setLoadingSend(false);
      setHandleResponse('')

      Swal.fire({
        icon: "success",
        title: "Alteração salva",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
    setLoadingSend(false)

      Swal.fire({
        icon: "error",
        title: "Erro ao salvar a solicitação",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const handleviewQuest =() => {
    let aux = denunce;
    dispatch(setDenunceObj(aux))
    dispatch(changePage(44))
  }

  return (
    <div style={{ paddingBottom: "20px" }}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            variant="text"
            style={{
              fontSize: "15px",
            }}
            onClick={() => dispatch(changePage(42))}
          >
            <ArrowBackIosTwoTone />
          </Button>
        </Grid>
        <Grid item>
          <Typography style={{ fontSize: "24px" }}>
            Detalhes da denúncia
          </Typography>
        </Grid>
      </Grid>
      <>
        <Card style={{ marginTop: 10 }}>
          <CardContent>
            <Grid
              container
              direction="column"
              alignItems="stretch"
              justifyContent="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      component="h4"
                      style={{ width: "100%", marginBottom: "20px" }}
                    >
                      n° de protocolo :{denunce.protocol}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Tipo de denunciante
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          {denunce.company_relation}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Tipo de denúncia
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          {denunce.relate_Type}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Data de criação
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          {Intl.DateTimeFormat("pt-br").format(denunce.date)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Data prevista
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          {dateParamersDenunce.map(
                            (item) => item.expectedDate
                          ) == ""
                            ? "15 Dias"
                            : denunce.Sensitive == "Muito sensível"
                            ? dateParamersDenunce.map(
                                (item) => item.expectedDAteSensive
                              ) + " Dias"
                            : dateParamersDenunce.map(
                                (item) => item.expectedDate
                              ) + " Dias"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography variant="h6" component="h6">
                            Status
                          </Typography>
                        </Grid>
                        {denunce.status !== "Concluído" && (
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="p"
                              color="textSecondary"
                            >
                              {denunce.status}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        {denunce.status !== "Concluído" ? (
                          <Grid item>
                            <FormControl style={{ width: "20rem" }} fullWidth>
                              <InputLabel
                                variant="outlined"
                                id="demo-simple-select-label"
                              >
                                Selecione o status
                              </InputLabel>
                              <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Selecione o status"
                                value={handleStatus}
                                onChange={(e) =>
                                  setHandleStatus(e.target.value)
                                }
                              >
                                <MenuItem value={"Aberto"}>Aberto</MenuItem>
                                <MenuItem value={"Em andamento"}>
                                  Em andamento
                                </MenuItem>
                                <MenuItem value={"Concluído"}>
                                  Concluído
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : (
                          <Typography
                            variant="body1"
                            component="p"
                            color="textSecondary"
                          >
                            {"" + denunce.status}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography variant="h6" component="h6">
                            Sensibilidade
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      {denunce.Sensitive == undefined ? (
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <FormControl style={{ width: "20rem" }} fullWidth>
                              <InputLabel
                                variant="outlined"
                                id="demo-simple-select-label"
                              >
                                Selecione o grau de sensibilidade
                              </InputLabel>
                              <Select
                                variant="outlined"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Selecione o grau de sensibilidade"
                                value={handleSensitive}
                                onChange={(e) =>setHandleSensitive( e.target.value,) }
                              >
                                <MenuItem value={"sensível"}>sensível</MenuItem>
                                <MenuItem value={"Muito sensível"}>
                                  Muito sensível
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          color="textSecondary"
                        >
                          : {"" + denunce.Sensitive}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <ZoomForm denunce={denunce} />

              {!state.done && (
                <Zoom
                  in={!state.done}
                  style={{
                    transitionDelay: !state.done ? "500ms" : "0ms",
                  }}
                >
                  <ResponseForm
                    denunce={denunce}
                    list_document_Response={list_document_Response}
                    setList_document_Response={setList_document_Response}
                    response={handleResponse}
                    setHandleResponse={setHandleResponse}
                    formResponseFile={formResponseFile}
                    setFormResponseFile={setFormResponseFile}
                    teste={teste}
                    setTeste={setTeste}
                  />
                </Zoom>
              )}
            </Grid>

            <ApurationForm
              denunce={denunce}
              status={handleStatus}
              verificationConclusation={verificationConclusation}
              setVerificationConclusation={setVerificationConclusation}
              cpf={cpf}
              setCpf={setCpf}
              name={name}
              setName={setName}
              company_relation_user={company_relation_user}
              setCompany_relation_user={setCompany_relation_user}
              email={email}
              setEmail={setEmail}
              verificationCheckBox={verificationCheckBox}
              setVerificationCheckBox={setVerificationCheckBox}
            />
          </CardContent>
        </Card>
        <br />

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            {denunce.status != "Concluído" && (
              <Button
                onClick={() => setConfimationDialog(true)}
                variant="contained"
                style={
                  handleStatus == ""
                    ? {
                        background: theme.opacity,
                        color: theme.secondary_color,
                        fontSize: "20px",
                      }
                    : {
                        background: theme.primary_color,
                        color: theme.secondary_color,
                        fontSize: "20px",
                      }
                }
                disabled={handleStatus == ""}
                startIcon={!loadingSend && <SaveTwoTone />}
              >
                {loadingSend ? <CircularProgress /> : "Salvar"}
              </Button>
            )}
          </Grid>
          <Grid item>
            {denunce.status == "Concluído" && (
              <Button
                variant="contained"
                style={{
                  background: theme.primary_color,
                  color: theme.secondary_color,
                  fontSize: "20px",
                }}
                onClick={() => handleviewQuest(denunce)}
                startIcon={<PictureAsPdfIcon />}
              >
                PDF
              </Button>
            )}
          </Grid>
        </Grid>

        <Dialog open={confimationDialog}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            Aviso!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
                direction="row"
                container
                justifyContent="space-between"
              >
                <DialogContentText>
                  <Typography variant="h5">
                    Você não poderá mais editar nenhum campo tem certeza que
                    quer continuar ?
                  </Typography>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                saveApurationDenunce();
                setConfimationDialog(false);
                setAcept(true);
              }}
              style={{
                backgroundColor: theme.primary_color,
              }}
            >
              Aceitar
            </Button>

            <Button
              onClick={() => setConfimationDialog(false)}
              style={{
                backgroundColor: theme.warning_color,
              }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  solicitationId: state.solicitationReducer.solicitationId,
});

export default connect(mapStateToProps, {
  changePage,
  setDenunceObj,
})(DetailsRefatore);
