import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import routerHistory from './core/infrastructure/routerHistory'
import { Provider } from 'react-redux'
import store from '../src/core/redux/store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import CookiePopup from './cookiePolicy/presentation/components/cookiePopup'
import ErrorBoundary from './shared/components/ErrorBoundary'
import Routes from './routes/Routes'
import ReactPwa from "react-pwa-app";
import { CircularProgress, Grid } from '@material-ui/core';
import "firebase/compat/functions";
import myApp from './core/infrastructure/firebaseConfig'
import { getUserInformation } from './dashboard/command/getUserInformation'
import Cookies from 'js-cookie'
import { render } from '@testing-library/react'

// if (process.env.NODE_ENV !== "production") {
//   myApp.functions().useEmulator("localhost", 5001);
// //   myApp.firestore().useEmulator("localhost", 8080);
// //  myApp.storage().useEmulator("localhost", 9199);
// //  myApp.auth().useEmulator("http://localhost:9099");
// }



ReactDOM.render(
  <ReactPwa
    test
    suspense={<Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      style={{height: "100vh", width: "100vw"}}
    >
      <CircularProgress size={60} color="#193993"/>
    </Grid>}
    config={{
      swUrl: "/service-worker.js",
    }}
  
  >
    
    
    <Provider store={store}>
      <Router history={routerHistory}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ErrorBoundary>
            <Routes/>
          </ErrorBoundary>
          <CookiePopup/>
        </MuiPickersUtilsProvider>
      </Router>
    </Provider>
  </ReactPwa>,
  document.getElementById('root')
)
