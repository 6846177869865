import React, { Component } from 'react';

import { Grid, Typography, IconButton, Dialog, DialogContent, Button, TextField, Divider, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import InputMask from 'react-input-mask';
import validateCNPJ from '../../../../shared/commands/validations/validateCNPJ';
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import routerHistory from '../../../../core/infrastructure/routerHistory';

import theme from '../../../../core/theme.json'

// TODO: .where('active', '==', true) onde collection('companies').get();

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={inputRef}
      mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/] || [/[0-9]/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      showMask
    />
  );
}

class EditCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialog: false,

      nickName: "",
      name: "",
      porteEmpresa: "",
      document: "",
      validDocument: true,
      dpo_isCompanyEmployee: false,

      nickNameDPO: "",
      nameDPO: "",
      documentDPO: "",
      dpoEmail: "",
      dpoPhone: "",
      dpoCompanyCnpj: "",
      dpoCompanyName: "",
      dpoStreet: "",
      dpoNumber: "",
      dpoComplement: "",
      dpoCity: "",
      dpoNeighborhood: "",
      dpoState: "",
      dpoCEP: "",

      validDocumentDPO: true,

      companyObj: null,

      disabledButton: false,

      deleteDialog: false,
    }
  }

  handleIsCompanyEmployee(event) {
    this.setState({
      dpo_isCompanyEmployee: !this.state.dpo_isCompanyEmployee
    }, () => this.handleButton());
  }

  handleDpoEmail(event) {
    this.setState({
      dpoEmail: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoCEP(event) {
    this.setState({
      dpoCEP: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoState(event) {
    this.setState({
      dpoState: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoCity(event) {
    this.setState({
      dpoCity: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoNeighborhood(event) {
    this.setState({
      dpoNeighborhood: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoComplement(event) {
    this.setState({
      dpoComplement: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoNumber(event) {
    this.setState({
      dpoNumber: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoStreet(event) {
    this.setState({
      dpoStreet: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoCompanyName(event) {
    this.setState({
      dpoCompanyName: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoCompanyCnpj(event) {
    this.setState({
      dpoCompanyCnpj: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDpoPhone(event) {
    this.setState({
      dpoPhone: event.target.value
    }, () => { this.handleButton(); });
  }
  handlePorteEmpresa(event) {
    this.setState({
      porteEmpresa: event.target.value
    }, () => { this.handleButton(); });
  }


  componentDidUpdate(prevProps) {
    if (prevProps.dialog !== this.props.dialog) {
      this.handleDialog();
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    }, () => { this.getCompanyInfo(); })
  }

  handleNickName(event) {
    this.setState({
      nickName: event.target.value
    }, () => { this.handleButton(); });
  }

  handleName(event) {
    this.setState({
      name: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDocument(event) {
    this.setState({
      document: event.target.value
    });

    if (this.validateDocument(event.target.value)) {
      this.setState({
        validDocument: true
      }, () => { this.handleButton(); });
    } else {
      this.setState({
        validDocument: false
      }, () => { this.handleButton(); });
    }
  }

  handleNickNameDPO(event) {
    this.setState({
      nickNameDPO: event.target.value
    }, () => { this.handleButton(); });
  }

  handleNameDPO(event) {
    this.setState({
      nameDPO: event.target.value
    }, () => { this.handleButton(); });
  }

  handleDocumentDPO(event) {
    this.setState({
      documentDPO: event.target.value
    });

    if (this.validateDocument(event.target.value)) {
      this.setState({
        validDocumentDPO: true
      }, () => { this.handleButton(); });
    } else {
      this.setState({
        validDocumentDPO: false
      }, () => { this.handleButton(); });
    }
  }

  validateDocument(document) {
    return validateCNPJ(document);
  }

  handleButton() {
    if (this.state.nickName !== undefined && this.state.nickName.length > 0 &&
      this.state.name !== undefined && this.state.name.length > 0 &&
      this.state.validDocument &&
      this.state.nameDPO !== undefined && this.state.nameDPO.length > 0 &&
      // this.state.nickNameDPO !== undefined && this.state.nickNameDPO.length > 0 &&
      this.state.validDocumentDPO
    ) {
      this.setState({
        disabledButton: false,
      })
    }
    else {
      this.setState({
        disabledButton: true,
      })
    }
  }

  async getCompanyInfo() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    try {
      let _company = await database.collection('companies').doc(_companyId).get();

      console.log(_company.data());

      this.setState({
        nickName: _company.data().nickname,
        name: _company.data().nome,
        porteEmpresa: _company.data().companySize,
        document: _company.data().cnpj,
        dpo_isCompanyEmployee: _company.data().dpo_isCompanyEmployee,
        nickNameDPO: _company.data().dpo_nickname,
        nameDPO: _company.data().dpo,
        documentDPO: _company.data().dpo_cnpj,
        dpoEmail: _company.data().dpo_email,
        dpoPhone: _company.data().dpo_phone,
        dpoCompanyCnpj: _company.data().dpo_dpoCompanyCnpj,
        dpoCompanyName: _company.data().dpo_dpoCompanyName,
        dpoStreet: _company.data().dpo_street,
        dpoNumber: _company.data().dpo_number,
        dpoComplement: _company.data().dpo_complement,
        dpoCity: _company.data().dpo_city,
        dpoNeighborhood: _company.data().dpo_neighborhood,
        dpoState: _company.data().dpo_state,
        dpoCEP: _company.data().dpo_cep,


        companyObj: _company.data()
      })
    } catch (ex) {
      console.log(ex);
    }
  }

  async updateCompany() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    try {
      var _companyObj = this.state.companyObj

      _companyObj.nickname = this.state.nickName !== undefined ? this.state.nickName : "";
      _companyObj.nome = this.state.name !== undefined ? this.state.name : "";
      _companyObj.cnpj = this.state.document !== undefined ? this.state.document : "";
      _companyObj.companySize = this.state.porteEmpresa !== undefined ? this.state.porteEmpresa : "";
      _companyObj.dpo_nickname = this.state.nickNameDPO !== undefined ? this.state.nickNameDPO : "";
      _companyObj.dpo = this.state.nameDPO !== undefined ? this.state.nameDPO : "";
      _companyObj.dpo_cnpj = this.state.documentDPO !== undefined ? this.state.documentDPO : "";
      _companyObj.companySize = this.state.porteEmpresa !== undefined ? this.state.porteEmpresa : "";
      _companyObj.dpo_email = this.state.dpo_email !== undefined ? this.state.dpoEmail : "";
      _companyObj.dpo_phone = this.state.dpo_phone !== undefined ? this.state.dpoPhone : "";
      _companyObj.dpo_isCompanyEmployee = this.state.dpo_isCompanyEmployee;
      _companyObj.dpo_dpoCompanyCnpj = this.state.dpo_dpoCompanyCnpj !== undefined ? this.state.dpoCompanyCnpj : "";
      _companyObj.dpo_dpoCompanyName = this.state.dpo_dpoCompanyName !== undefined ? this.state.dpoCompanyName : "";
      _companyObj.dpo_street = this.state.dpo_street !== undefined ? this.state.dpoStreet : "";
      _companyObj.dpo_number = this.state.dpo_number !== undefined ? this.state.dpoNumber : "";
      _companyObj.dpo_complement = this.state.dpo_complement !== undefined ? this.state.dpoComplement : "";
      _companyObj.dpo_city = this.state.dpo_city !== undefined ? this.state.dpoCity : "";
      _companyObj.dpo_neighborhood = this.state.dpo_neighborhood !== undefined ? this.state.dpoNeighborhood : "";
      _companyObj.dpo_state = this.state.dpo_state !== undefined ? this.state.dpoState : "";
      _companyObj.dpo_cep = this.state.dpo_cep !== undefined ? this.state.dpoCEP : "";
      await database.collection('companies').doc(_companyId).update(_companyObj);

      document.location.reload(true);
    } catch (ex) {
      console.log(ex);
    }
  }

  handleDeleteDialog() {
    this.setState({
      deleteDialog: !this.state.deleteDialog
    })
  }

  async deleteCompany() {
    let database = myApp.firestore();
    let _companyId = localStorage.getItem('currentCompany');

    try {
      var _companyObj = this.state.companyObj

      _companyObj.active = false;

      await database.collection('companies').doc(_companyId).update(_companyObj);

      localStorage.setItem('currentCompany', "");
      routerHistory.push("/company");
    } catch (ex) {
      console.log(ex);
    }
  }

  render() {
    const ERROR_MESSAGE_CNPJ = !this.state.validDocument ? "Digite um CNPJ válido" : "";

    return (
      <div>
        <Dialog
          open={this.state.dialog}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={() => { this.handleDialog() }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item style={{ width: "100%", padding: "36px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                  spacing={4}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      spacing={4}
                    >
                      <Grid item container direction="row">
                        <Grid item style={{ width: "100%" }} xs={10}>
                          <Typography variant="h5" component="h5" >
                            Editar Informações da Empresa
                          </Typography>
                        </Grid>
                        <Grid item container xs={2} justify="flex-end">
                          <IconButton onClick={() => this.handleDialog()}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          inputProps={{
                            maxLength: 50,
                          }}
                          label={"Apelido da empresa"}
                          variant={"outlined"}
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.nickName}
                          onChange={(event) => { this.handleNickName(event) }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          inputProps={{
                            maxLength: 115,
                          }}
                          label="Razão Social"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.name}
                          onChange={(event) => { this.handleName(event) }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <FormControl fullWidth>
                          <InputLabel variant="outlined" id="demo-simple-select-label" >Porte da empresa</InputLabel>
                          <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.porteEmpresa}
                            label="Porte da empresa"
                            onChange={(event) => this.handlePorteEmpresa(event)}
                          >
                            <MenuItem value={'Pequeno'}>Pequeno</MenuItem>
                            <MenuItem value={'Médio'}>Médio</MenuItem>
                            <MenuItem value={'Grande'}>Grande</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          label="CNPJ"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.document}
                          error={!this.state.validDocument}
                          helperText={ERROR_MESSAGE_CNPJ}
                          onChange={(event) => { this.handleDocument(event) }}
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <Divider />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <Typography variant="h5" component="h5" >
                          Editar Informações do DPO
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          inputProps={{
                            maxLength: 50,
                          }}
                          label="Nome do DPO"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.nameDPO}
                          onChange={(event) => { this.handleNameDPO(event) }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField
                          label="CNPJ DPO"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.documentDPO}
                          error={!this.state.validDocumentDPO}
                          helperText={ERROR_MESSAGE_CNPJ}
                          onChange={(event) => { this.handleDocumentDPO(event) }}
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField

                          label="Email do DPO"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.dpoEmail}
                          onChange={(event) => { this.handleDpoEmail(event) }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <TextField

                          label="Telefone do DPO"
                          variant="outlined"
                          style={{ width: "100%" }}
                          color={theme.secondary_color}
                          value={this.state.dpoPhone}
                          onChange={(event) => { this.handleDpoPhone(event) }}
                        />
                      </Grid>
                      <Grid item style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.dpo_isCompanyEmployee}
                              onChange={(event) => { this.handleIsCompanyEmployee(event) }}
                              color="primary"
                            />
                          }
                          label="É funcionário da empresa"
                        />
                      </Grid>
                      {this.state.dpo_isCompanyEmployee === false ?
                        (

                          <><Grid item style={{ width: "100%" }}>
                            <Typography variant="h5" component="h5">
                              Editar Informações da Empresa do DPO
                            </Typography>
                          </Grid><Grid item style={{ width: "100%" }}>
                              <TextField
                                label="CNPJ da Empresa do DPO"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoCompanyCnpj}
                                // error={!this.state.validDocumentDPO}
                                // helperText={ERROR_MESSAGE_CNPJ}
                                onChange={(event) => { this.handleDpoCompanyCnpj(event); }}
                                InputProps={{
                                  inputComponent: TextMaskCustom,
                                }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Razão Social da Empresa do DPO"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoCompanyName}
                                onChange={(event) => { this.handleDpoCompanyName(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="CEP da Empresa"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoCEP}
                                onChange={(event) => { this.handleDpoCEP(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Logradouro da Empresa"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoStreet}
                                onChange={(event) => { this.handleDpoStreet(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Número"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoNumber}
                                onChange={(event) => { this.handleDpoNumber(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Complemento"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoComplement}
                                onChange={(event) => { this.handleDpoComplement(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Bairro"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoNeighborhood}
                                onChange={(event) => { this.handleDpoNeighborhood(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Cidade"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoCity}
                                onChange={(event) => { this.handleDpoCity(event); }} />
                            </Grid><Grid item style={{ width: "100%" }}>
                              <TextField

                                label="Estado"
                                variant="outlined"
                                style={{ width: "100%" }}
                                color={theme.secondary_color}
                                value={this.state.dpoState}
                                onChange={(event) => { this.handleDpoState(event); }} />
                            </Grid></>

                        ) : <></>
                      }
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item style={{ width: "100%" }} xs={2}>
                        <Button
                          variant="contained"
                          style={{ background: "red", color: "white", fontSize: theme.font_button }}
                          onClick={() => { this.handleDeleteDialog() }}
                        >
                          Remover
                        </Button>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }} />
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ background: this.state.disabledButton ? "#91ff79" : theme.primary_color, color: theme.secondary_color, fontSize: theme.font_button }}
                          onClick={() => { this.updateCompany() }}
                          disabled={this.state.disabledButton}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.deleteDialog}
          fullWidth={true}
          maxWidth={"md"}
          onClose={() => { this.handleDeleteDialog(); }}
        >
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item style={{ width: "100%", padding: "36px" }}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="h5" component="h5" style={{ textAlign: "center" }} >
                      Você realmente deseja apagar essa empresa?
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <ErrorOutlineIcon style={{ width: "100%", height: "160px", color: "red" }} />
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Grid
                      container
                      direction="row"
                    >
                      <Grid item style={{ width: "100%" }} xs={2}>
                        <Button
                          variant="contained"
                          style={{ background: "#193993", color: "white", height: "48px", width: "168px", fontSize: "16px" }}
                          onClick={() => { this.handleDeleteDialog(); }}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item style={{ flexGrow: 1 }} />
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ background: "red", color: "white", height: "48px", width: "168px", fontSize: "16px" }}
                          onClick={() => { this.deleteCompany() }}
                        >
                          Confirmar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default EditCompany;