import React, { useEffect, useState } from 'react'
import {
  AccordionActions,
  Button,
  Divider,
  Grid, InputAdornment,
  InputLabel, LinearProgress, MenuItem,
  Select, Snackbar,
  TextField,
  Zoom
} from '@material-ui/core'
import {
  EmailTwoTone,
  HttpTwoTone,
  LockTwoTone, PersonTwoTone, RestoreTwoTone,
  SaveTwoTone,
  SecurityTwoTone, SendTwoTone,
  SettingsInputCompositeTwoTone,
} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from '../../../../shared/components/ErrorBoundary'
import FormControl from '@material-ui/core/FormControl'
import Link from '@material-ui/core/Link'
import myApp from '../../../../core/infrastructure/firebaseConfig'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Alert } from '@material-ui/lab'
import firebase from 'firebase/compat/app'

const sendSolicitationChannelEmail = myApp.functions().httpsCallable('sendSolicitationChannelEmail')

const useStyles = makeStyles((theme) => ({
  details: {
    alignItems: 'center',
  },
  saveButtom: {
    backgroundColor: '#38f205'
  },
  formControl: {
    maxWidth: '500px',
  },
}))

function SmtpConfig (props) {
  const classes = useStyles()

  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchedData, setFetchedData] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)
  const [emailTest, setEmailTest] = useState('')
  const [showEmailTestSnack, setShowEmailTestSnack] = useState(false)
  const [emailTestSnackData, setEmailTestSnackData] = useState({
    type: 'success',
    message: 'Email enviado com sucesso!'
  })

  const [showSaveSnack, setShowSaveSnack] = useState(false)
  const [saveSnackData, setSaveSnackData] = useState({
    type: 'success',
    message: 'Configurações de e-mail salvas com sucesso!'
  })

  const [smtpName, setSmtpName] = useState('')
  const [smtpEmail, setSmtpEmail] = useState('')
  const [smtpService, setSmtpService] = useState('default')

  const [gmailUser, setGmailUser] = useState('')
  const [gmailPass, setGmailPass] = useState('')

  const [smtpHost, setSmtpHost] = useState('')
  const [smtpPort, setSmtpPort] = useState('')
  const [smtpCrypto, setSmtpCrypto] = useState('')
  const [smtpAuthType, setSmtpAuthType] = useState('')
  const [smtpUser, setSmtpUser] = useState('')
  const [smtpPass, setSmtpPass] = useState('')

  const fkCompanyGroup = props.type ? localStorage.getItem('currentCompany') : JSON.parse(sessionStorage.getItem('@blue-legal/userInformation')).fk_company_group;
  const solicitationUrl = props.type ? `/solicitation/client/company/${fkCompanyGroup}` : `#/solicitation/client/${fkCompanyGroup}`;
  const [userAdmin, setUserAdmin] = useState(localStorage.getItem('userAdmin') === 'sim' ? false : true);

  const handleSave = async event => {
    event.preventDefault()
    setIsSaving(true)

    try {
      const res = await myApp
        .firestore()
        .collection('solicitationParameters')
        .where('fk_company_group', '==', fkCompanyGroup)
        .get()

      if (res?.docs?.length) {
        const solicitatinParametersId = res.docs.shift().id
        let docRef = myApp
          .firestore()
          .collection('solicitationParameters')
          .doc(solicitatinParametersId)

        let emailConfig = {
          service: smtpService,
          name: smtpName,
          email: smtpEmail,
          smtp: {},
        }

        switch (smtpService) {
          case 'gmail':
            emailConfig.email = gmailUser
            emailConfig.smtp = {
              host: 'smtp.gmail.com',
              port: 587,
              secure: false,
              auth: {
                user: gmailUser,
                pass: gmailPass
              }
            }
            await docRef
              .update({
                emailConfig
              })
            break
          case 'other':
            emailConfig.authType = smtpAuthType
            emailConfig.smtp = {
              host: smtpHost,
              port: smtpPort,
              secure: smtpCrypto === 'ssl',
            }
            if (smtpAuthType === 'login') {
              emailConfig.smtp.auth = {
                user: smtpUser,
                pass: smtpPass
              }
            }
            await docRef
              .update({
                emailConfig
              })
            break
          default:
            await docRef
              .update({
                "emailConfig.service": smtpService,
                "emailConfig.name": firebase.firestore.FieldValue.delete(),
                "emailConfig.smtp": firebase.firestore.FieldValue.delete(),
                "emailConfig.email": firebase.firestore.FieldValue.delete(),
              })
        }

        setIsSaving(false)
        setShowSaveSnack(true)
        setSaveSnackData({
          type: 'success',
          message: 'Configurações de e-mail salvas com sucesso!'
        })
      }
    } catch (e) {
      console.log(e)
      setIsSaving(false)
      setShowSaveSnack(true)
      setSaveSnackData({
        type: 'error',
        message: 'Ocorreu um erro ao salvar as configurações de e-mail'
      })
    }

    return false
  }

  const handleRestore = event => {
    setSmtpService('default')
  }

  const handleTestEmail = async event => {
    setIsSendingTestEmail(true)
    try {
      await sendSolicitationChannelEmail({
        email: emailTest,
        subject: 'E-mail de Teste',
        message: `Este é um e-mail de teste.<br><br>`,
        company: fkCompanyGroup,
      })
      setIsSendingTestEmail(false)
      setShowEmailTestSnack(true)
      setEmailTestSnackData({
        type: 'success',
        message: 'Email enviado com sucesso!'
      })
    } catch (e) {
      setIsSendingTestEmail(false)
      setShowEmailTestSnack(true)
      setEmailTestSnackData({
        type: 'error',
        message: `Erro: ${e.message}`
      })
    }
  }

  const renderAuthType = () => {
    switch (smtpAuthType) {
      case 'login':
        return (
          <>
            <Grid item>
              <Grid
                container
                spacing={2}
              >
                <Grid item md={6}>
                  <Zoom in timeout={500}>
                    <TextField
                      id="smtp-user"
                      label="Usuário"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={smtpUser}
                      required
                      onChange={event => setSmtpUser(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonTwoTone/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Zoom>
                </Grid>

                <Grid item md={6}>
                  <Zoom in timeout={600}>
                    <TextField
                      id="smtp-pass"
                      label="Senha"
                      variant="outlined"
                      fullWidth
                      type="password"
                      required
                      size="small"
                      value={smtpPass}
                      onChange={event => setSmtpPass(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockTwoTone/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Zoom>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      default:
        return null
    }
  }

  const renderSmtpConfigFields = () => {
    switch (smtpService) {
      case 'gmail':
        return (
          <>
            <Grid item>
              <TextField
                id="smtp-name"
                label="Nome utilizado para enviar os e-mails"
                variant="outlined"
                required
                fullWidth
                size="small"
                value={smtpName}
                onChange={event => setSmtpName(event.target.value)}
              />
            </Grid>

            <Grid item>
              <Zoom in timeout={500}>
                <TextField
                  id="gmail-user"
                  label="E-mail do Google"
                  variant="outlined"
                  fullWidth
                  type="email"
                  size="small"
                  value={gmailUser}
                  onChange={event => setGmailUser(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailTwoTone/>
                      </InputAdornment>
                    ),
                  }}
                />
              </Zoom>
            </Grid>

            <Grid item>
              <Zoom in timeout={600}>
                <TextField
                  id="gmail-pass"
                  label="Senha"
                  variant="outlined"
                  fullWidth
                  type="password"
                  size="small"
                  value={gmailPass}
                  onChange={event => setGmailPass(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockTwoTone/>
                      </InputAdornment>
                    ),
                  }}
                />
              </Zoom>
            </Grid>
          </>
        )

      case 'other':
        return (
          <>
            <Grid item>
              <TextField
                id="smtp-name"
                label="Nome utilizado para enviar os e-mails"
                variant="outlined"
                required
                fullWidth
                size="small"
                value={smtpName}
                onChange={event => setSmtpName(event.target.value)}
              />
            </Grid>

            <Grid item>
              <TextField
                id="smtp-email"
                label="Enviar a partir do e-mail"
                variant="outlined"
                fullWidth
                type="email"
                required
                size="small"
                value={smtpEmail}
                onChange={event => setSmtpEmail(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailTwoTone/>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Zoom in timeout={500}>
                <TextField
                  id="smtp-host"
                  label="Host"
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                  value={smtpHost}
                  onChange={event => setSmtpHost(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpTwoTone/>
                      </InputAdornment>
                    ),
                  }}
                />
              </Zoom>
            </Grid>

            <Grid item>
              <Zoom in timeout={600}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item md={6}>
                    <TextField
                      id="smtp-port"
                      label="Porta"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      size="small"
                      value={smtpPort}
                      onChange={event => setSmtpPort(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SettingsInputCompositeTwoTone/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <TextField
                      id="smtp-crypto"
                      label="Criptografia"
                      variant="outlined"
                      fullWidth
                      size="small"
                      select
                      required
                      value={smtpCrypto}
                      onChange={event => setSmtpCrypto(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SecurityTwoTone/>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="none">Nenhuma</MenuItem>
                      <MenuItem value="ssl">SSL</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Zoom>
            </Grid>

            <Grid item>
              <Zoom in timeout={700}>
                <TextField
                  id="smtp-auth-type"
                  label="Modo de autenticação"
                  variant="outlined"
                  fullWidth
                  size="small"
                  select
                  required
                  value={smtpAuthType}
                  onChange={event => setSmtpAuthType(event.target.value)}
                >
                  <MenuItem value="none">Nenhuma</MenuItem>
                  <MenuItem value="login">Login</MenuItem>
                </TextField>
              </Zoom>
            </Grid>

            {renderAuthType()}
          </>
        )
      default:
        return null
    }
  }

  const fetchConfigs = async () => {
    const fkCompany = props.type ? localStorage.getItem('currentCompany') : JSON.parse(sessionStorage.getItem('@blue-legal/userInformation')).fk_company_group;
    const res = await myApp
      .firestore()
      .collection('solicitationParameters')
      .where('fk_company_group', '==', fkCompany)
      .get()

    if (res.docs.length) {
      const solicitatinParameters = res.docs.shift().data();

      setSmtpName(solicitatinParameters.emailConfig.name);
      setSmtpEmail(solicitatinParameters.emailConfig.email);

      switch (solicitatinParameters?.emailConfig?.service) {
        case 'gmail':
          setSmtpService('gmail');
          setGmailUser(solicitatinParameters.emailConfig.smtp.auth.user);
          setGmailPass(solicitatinParameters.emailConfig.smtp.auth.pass);
          break;
        case 'other':
          setSmtpService('other');
          setSmtpCrypto(solicitatinParameters.emailConfig.smtp.secure ? 'ssl' : 'none')
          setSmtpAuthType(solicitatinParameters.emailConfig.authType)
          if (solicitatinParameters.emailConfig.authType === 'login') {
            setSmtpUser(solicitatinParameters.emailConfig.smtp.auth.user);
            setSmtpPass(solicitatinParameters.emailConfig.smtp.auth.pass);
          }
          break;
        default:
          setSmtpService('default');
      }
    }
    setFetchedData(true);
    setIsLoading(false);
  }

  useEffect( () => {

    

    if (!isLoading && isExpanded && !fetchedData) {
      setIsLoading(true);
      fetchConfigs()
    }
  },[isLoading, isExpanded, fetchedData])


  return (
    <ErrorBoundary>
      <form onSubmit={handleSave}>
        <Accordion
          expanded={isExpanded}
          onChange={(event, expanded) => {
            setIsExpanded(expanded)
            if (!expanded) {
              setIsLoading(false)
              setFetchedData(false)
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>Configurações de e-mail</Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.details}>
            <Grid
              container
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item md={5}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={2}
                  className={classes.formControl}
                >
                  {
                    isLoading ? (
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                        >
                          <Grid item>
                            <LinearProgress/>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                            required
                          >
                            <InputLabel htmlFor="smtp-service-label">Serviço para envio de e-mail</InputLabel>

                            <Select
                              labelId="smtp-service-label"
                              id="smtp-service"
                              label="Serviço para envio de e-mail"
                              value={smtpService}
                              onChange={event => setSmtpService(event.target.value)}
                            >
                              <MenuItem value="default">Padrão</MenuItem>
                              <MenuItem value="gmail">Gmail</MenuItem>
                              <MenuItem value="other">Outro servidor SMTP</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {renderSmtpConfigFields()}
                      </>
                    )
                  }
                </Grid>
              </Grid>

              <Grid item md={5}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="caption">
                      Esta configuração determina o remetente, dos e-mails recebidos
                      pelos usuários da <Link href={solicitationUrl} target="_blank">Ficha de Solicitação</Link>.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      id="test-email"
                      label="E-mail de teste"
                      variant="outlined"
                      fullWidth
                      type="email"
                      size="small"
                      value={emailTest}
                      onChange={event => setEmailTest(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailTwoTone/>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={isSendingTestEmail ? undefined : <SendTwoTone/>}
                      onClick={handleTestEmail}
                      disabled={isSendingTestEmail}
                    >
                      {
                        isSendingTestEmail ? (
                          <><CircularProgress size={24} style={{ marginRight: '5px' }}/> Enviando...</>
                        ) : 'Enviar e-mail de teste'
                      }
                    </Button>
                    <Snackbar
                      open={showEmailTestSnack}
                      autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      onClose={() => setShowEmailTestSnack(false)}
                    >
                      <Alert
                        severity={emailTestSnackData.type}
                        onClose={() => setShowEmailTestSnack(false)}
                      >
                        {emailTestSnackData.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>

          <Divider/>

          <AccordionActions>
            <Button
              variant="contained"
              startIcon={<RestoreTwoTone/>}
              onClick={handleRestore}
            >
              Restaurar padrão
            </Button>
            <Button
              className={classes.saveButtom}
              variant="contained"
              startIcon={isSaving ? undefined : <SaveTwoTone/>}
              type="submit"
              disabled={isSaving}
            >
              {isSaving ? <CircularProgress size={24}/> : 'Salvar'}
            </Button>
            <Snackbar
              open={showSaveSnack}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => setShowSaveSnack(false)}
            >
              <Alert
                severity={saveSnackData.type}
                onClose={() => setShowSaveSnack(false)}
              >
                {saveSnackData.message}
              </Alert>
            </Snackbar>
          </AccordionActions>
        </Accordion>
      </form>
    </ErrorBoundary>
  )
}

export default SmtpConfig