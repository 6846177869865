import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  ImageList,
  ImageListItem,
  CircularProgress,
  Card
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../../../core/theme.json";
import myApp from "../../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/auth";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowBackIosTwoTone } from "@material-ui/icons";
import Title from "../../../../shared/components/title";
import SmtpConfig from "./smtpConfig";
import UrlConfig from './urlConfig'
import { connect } from "react-redux";

const ParameterHolders = (props) => {
  //variaveis auxiliares
  let db = myApp.firestore();
  let dbA = myApp.auth();
  let currentCompany = localStorage.getItem("currentCompany");
  let lista = [];
  let listaSolicitationTypes = [];
  const dispatch = useDispatch();
  let fileInput = React.createRef();

  //states
  const [name, setName] = useState("");
  const [company, setCompany] = useState(localStorage.getItem("currentCompany"));
  const [getFkGompanyGroup, setFkGompanyGroup] = useState(JSON.parse(sessionStorage.getItem("@blue-legal/userInformation")));
  const [validation, setValidation] = useState(false);
  const [parameterizationTypeState, setParameterizationTypeState] = useState(props.parameterizationType);

  //tabela de  informações dos titulares
  const [dataDb, setDataDb] = useState([]);
  const [newListHolders, setNewListHolders] = useState([]);

  //informações dos titulares
  const [holderId, setHolderId] = useState("");
  const [holderName, setHolderName] = useState("");
  const [stateNameHolder, setStateNameHolder] = useState("");

  //filto e paginação
  const [filterName, setFilterName] = useState(true);
  const [filterSolicitationTypesFilter, setSolicitationTypesFilter] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [activePageSolicitation, setActivePageSolicitation] = useState(1);

  //dialogs
  const [dialogDelete, setDialogDelete] = useState(false)
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const [dialogUpdateConsentText, setDialogUpdateConsentText] = useState(false);
  const [dialogStatusChangeLogo, setDialogStatusChangeLogo] = useState(false);
  const [dialogUpdateSolicitationType, setDialogUpdateSolicitationType] = useState(false);
  const [dialogDeleteSolicitationType, setDialogDeleteSolicitationType] = useState(false);
  const [dialogWarningImageSize, setDialogWarningImageSize] = useState(false);
  const [dialogUpdateIntroductoryText, setDialogUpdateIntroductoryText] = useState(false);

  //ficha de consentimento
  const [consentFormId, setConsentFormId] = useState("");
  const [consentFormConsentText, setConsentFormConsentText] = useState("");
  const [consentFormConsentLogo, setConsentFormConsentLogo] = useState("");
  const [consentFormConsentLogoPath, setConsentFormConsentLogoPath] = useState("");
  const [consentFormConsentNewLogo, setConsentFormConsentNewLogo] = useState(null);
  const [consentFormStatusChangeLogo, setConsentFormStatusChangeLogo] = useState('');
  const [getExpectedDate, setExpectedDate] = useState();
  const [getIntroductoryText, setIntroductoryText] = useState("");
  const [getPrivacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [callCenterLink, setCallCenterLink] = useState("");

  //tipo de solicitação
  const [dataSolicitationTypesList, setDataSolicitationTypesList] = useState([]);
  const [newSolicitationTypesList, setNewSolicitationTypesList] = useState([]);
  const [newSolicitationTypes, setNewSolicitationTypes] = useState("");
  const [requestTypeSearch, setRequestTypeSearch] = useState("");

  //editar e excluir tipos de solicitação
  const [solicitationSelectedId, setSolicitationSelectedId] = useState("");
  const [solicitationSelectedText, setSolicitationSelectedText] = useState("");

  //state para visualização da imagem
  const [imgTeste, setImgTeste] = useState(null);

  const [userAdmin, setUserAdmin] = useState(localStorage.getItem('userAdmin') === 'sim' ? false : true);

  //funções
  useEffect(() => {
    handleDataVerification();
  }, []);

  const handleDataVerification = async () => {
    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      let querySnapshot = await db.collection("solicitationParameters").where("fk_company_group", "==", company).get();
      if (querySnapshot.docs.length > 0) {
        getConsentForm();
        getHolders();
        getSolicitationTypes();
        setValidation(true)
        console.log('Achou os dados')
      } else {
        db.collection("solicitationParameters").doc().set({
          fk_company_group: company,
          privacyPolicyUrl: "",
          serviceChannelUrl: "",
          logoUrl: "https://firebasestorage.googleapis.com/v0/b/bluelegal-lgpd-dev.appspot.com/o/solicitationParameters%2F02OjXR1NCTL42xv0aYR8%2Fdashboard_logo.png?alt=media&token=d51ca5bc-a991-4ab0-a7a4-2b1a158f711c",
          emailConfig: {
            service: "default"
          },
          from_email: "",
          from_name: "",
          to_email: "",
          consentText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum convallis hendrerit tellus, eget vehicula leo mollis eu. Aenean in enim vitae orci porttitor sagittis. Curabitur pharetra, ante id semper commodo, arcu elit ultricies risus, eu accumsan nisl quam nec ipsum. Phasellus augue ex, bibendum at tempus vitae, placerat rutrum odio. Ut pulvinar leo eu fermentum condimentum. Mauris tempor, quam sit amet porttitor egestas, metus arcu rutrum arcu, et ullamcorper erat leo s",
          expectedDate: "",
          dueDate: ""
        }).then(() => {
          getConsentForm();
          getHolders();
          getSolicitationTypes();
          setValidation(true)
        });
        console.log('Não achou os dados')
      }

    } else {
      let querySnapshot = await db.collection("solicitationParameters").where("fk_company_group", "==", getFkGompanyGroup.fk_company_group).get();

      if (querySnapshot.docs.length > 0) {
        getConsentForm();
        getHolders();
        getSolicitationTypes();
        setValidation(true)
        console.log('Achou os dados')
      } else {
        db.collection("solicitationParameters").doc().set({
          fk_company_group: getFkGompanyGroup.fk_company_group,
          privacyPolicyUrl: "",
          serviceChannelUrl: "",
          logoUrl: "https://firebasestorage.googleapis.com/v0/b/bluelegal-lgpd-dev.appspot.com/o/solicitationParameters%2F02OjXR1NCTL42xv0aYR8%2Fdashboard_logo.png?alt=media&token=d51ca5bc-a991-4ab0-a7a4-2b1a158f711c",
          emailConfig: {
            service: "default"
          },
          from_email: "",
          from_name: "",
          to_email: "",
          consentText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum convallis hendrerit tellus, eget vehicula leo mollis eu. Aenean in enim vitae orci porttitor sagittis. Curabitur pharetra, ante id semper commodo, arcu elit ultricies risus, eu accumsan nisl quam nec ipsum. Phasellus augue ex, bibendum at tempus vitae, placerat rutrum odio. Ut pulvinar leo eu fermentum condimentum. Mauris tempor, quam sit amet porttitor egestas, metus arcu rutrum arcu, et ullamcorper erat leo s",
          expectedDate: "",
          dueDate: ""
        }).then(() => {
          getConsentForm();
          getHolders();
          getSolicitationTypes();
          setValidation(true)
        });
        console.log('Não achou os dados')
      }
    }
  }

  //função responsável por fazer a busca dos titulares
  const getHolders = () => {
    if (parameterizationTypeState) {
      db.collection("holders").where("in_company", "==", company).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            name: doc.data().name
          });
        })
        setDataDb(lista);
        setNewListHolders(lista)
      })
    } else {
      db.collection("holders").where("in_company", "==", getFkGompanyGroup.fk_company_group).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          lista.push({
            id: doc.id,
            name: doc.data().name
          });
        })
        setDataDb(lista);
        setNewListHolders(lista)
      })
    }
  }

  //função responsável por buscar informações da ficha de consentimento
  const getConsentForm = async () => {
    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      let querySnapshot = await db.collection("solicitationParameters").where("fk_company_group", "==", company).get();
      setConsentFormId(querySnapshot.docs[0].id)
      setConsentFormConsentText(querySnapshot.docs[0].data().consentText);
      setConsentFormConsentLogo(querySnapshot.docs[0].data().logoUrl);
      setConsentFormConsentLogoPath(querySnapshot.docs[0].data().filePathLogoName);
      setExpectedDate(querySnapshot.docs[0].data().expectedDate)
      setIntroductoryText(querySnapshot.docs[0].data().introductoryText);
      setPrivacyPolicyUrl(querySnapshot.docs[0].data().privacyPolicyUrl);
      setCallCenterLink(querySnapshot.docs[0].data().callCenter);
    } else {
      let querySnapshot = await db.collection("solicitationParameters").where("fk_company_group", "==", getFkGompanyGroup.fk_company_group).get();
      setConsentFormId(querySnapshot.docs[0].id)
      setConsentFormConsentText(querySnapshot.docs[0].data().consentText);
      setConsentFormConsentLogo(querySnapshot.docs[0].data().logoUrl);
      setConsentFormConsentLogoPath(querySnapshot.docs[0].data().filePathLogoName);
      setExpectedDate(querySnapshot.docs[0].data().expectedDate)
      setIntroductoryText(querySnapshot.docs[0].data().introductoryText);
      setPrivacyPolicyUrl(querySnapshot.docs[0].data().privacyPolicyUrl);
      setCallCenterLink(querySnapshot.docs[0].data().callCenter);
    }
  }

  //registrando novos titulares
  const handleRegisterHolder = () => {
    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      if (name !== '') {
        db.collection("holders").doc().set({
          name: name,
          in_company: company,
        })
          .then(() => {
            setName("")
            getHolders()
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    } else {
      if (name !== '') {
        db.collection("holders").doc().set({
          name: name,
          in_company: getFkGompanyGroup.fk_company_group,
        })
          .then(() => {
            setName("")
            getHolders()
          })
          .catch((error) => {
            console.error("Error ao registrar titular ", error);
          });
      }
    }
  }

  //função responsável por ordenar a lista por ordem alfabética
  const handleSortAlphabeticalOrder = (filter) => {
    if (filter === 'name') {
      if (filterName) {
        setNewListHolders(dataDb.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        setFilterName(false);
      } else {
        setNewListHolders(dataDb.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        setFilterName(true);
      }
    } else if (filter === 'solicitationType') {
      if (filterSolicitationTypesFilter) {
        setNewSolicitationTypesList(dataSolicitationTypesList.sort((a, b) => a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1))
        setSolicitationTypesFilter(false);
      } else {
        setNewSolicitationTypesList(dataSolicitationTypesList.sort((a, b) => a.type.toLowerCase() < b.type.toLowerCase() ? 1 : -1))
        setSolicitationTypesFilter(true);
      }
    }
  }

  // Filtrando por titular
  const handleFilterHolder = (item) => {
    if (stateNameHolder !== '') {
      return item.name.toUpperCase().includes(stateNameHolder.toUpperCase());
    } else {
      return item;
    }
  }

  //limitando paginas de exibição
  const filterPage = (solicitation, index) => {
    let max = activePage * 6;
    let min = max - 6;

    if (index >= min && index < max) {
      return solicitation;
    }
  }

  //mudando de pagina de exibição
  const handleDisplayChangePage = (event, page) => {
    setActivePage(page)
  }

  //abrindo modal de exclusão e definindo id do titular a ser excluido
  const handleOpenDeleteDialog = (id) => {
    setHolderId(id)
    setDialogDelete(true)
  }

  //abrindo dialogo de atualização do titular
  const handleOpanUpdateDialog = (item) => {
    setHolderId(item.id)
    setHolderName(item.name)
    setDialogUpdate(true)
  }

  //função para excluir um titular 
  const handleDeleteButtonClick = () => {
    db.collection("holders").doc(holderId).delete()
    setDialogDelete(false)
    setHolderId("")
    getHolders()
  }

  //função para atualizar titular
  const handleUpdateButtonClick = () => {
    db.collection("holders").doc(holderId).update({
      name: holderName
    }).then(() => {
      setHolderId("")
      setHolderName("")
      setDialogUpdate(false)
      getHolders()
    })
  }

  //função para atualizar texto de consentimento
  const handleUpdateConsentText = () => {
    db.collection("solicitationParameters").doc(consentFormId).update({
      consentText: consentFormConsentText
    }).then(() => {
      setDialogUpdateConsentText(true)
    });
  }

  //função que faz referencia ao campo de input
  const focusfileInput = () => {
    fileInput.current.click();
  }

  //função para atualizar a logo selecionada
  const handleSelected = (e) => {
    //valor de referencia 3000000

    if (e.target.files[0].size > 3000000) {
      console.log('O tamanho do arquivo não pode ser maior que 3MB')
      setDialogWarningImageSize(true)
    } else {
      console.log('Pode enviar')
      let file = e.target.files[0];
      setImgTeste(URL.createObjectURL(e.target.files[0]))
      setConsentFormConsentNewLogo(e.target.files[0])
      let fileName = e.target.files[0].name;
    }

  }

  //alterando a logo da ficha 
  const handleLogoChange = () => {

    let file = consentFormConsentNewLogo;

    //pegando norme do arquivo
    let fileName = consentFormConsentNewLogo.name;

    //separando nome do arquivo em nome e extensão
    let fileDotsArray = fileName.split(".");

    //.shift() para pegar o primeiro item do array criado pelo split, que é o nome do arquivo
    let firstName = fileDotsArray.shift();

    //.pop() para pegar o ultimo item do array criado pelo split, que é a extensão do arquivo
    let extension = fileDotsArray.pop();

    //pegando a data em mili segundos
    let dateAdded = consentFormConsentNewLogo.lastModified;

    //criando chave de identificação unica para o arquivo, caso o usuario envie dois arquivos com mesmo nome
    let fileNameDb = myApp.database().ref().push().key + "-" + firstName;

    //caminho para o Storage
    let filePath = '';

    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      filePath =
        "solicitationParameters/" +
        company +
        "/" +
        fileNameDb;
    } else {
      filePath =
        "solicitationParameters/" +
        getFkGompanyGroup.fk_company_group +
        "/" +
        fileNameDb;
    }

    //criando referencia de arquivo
    let storage = myApp.storage().ref(filePath);

    //enviando arquivo
    let upload = storage.put(file);

    //abrindo dialog para acompanhar o progresso de upload
    setDialogStatusChangeLogo(true)

    //função responsável por tratar o processo de upload
    trackUpload(upload).then(() => {
      storage.getDownloadURL().then((downloadUrl) => {
        myApp.firestore().collection("solicitationParameters").doc(consentFormId).update({
          dateLogo: dateAdded,
          extensionLogo: extension,
          fileLogoName: firstName,
          filePathLogoName: filePath,
          logoUrl: downloadUrl,
        });
        myApp.storage().ref().child(consentFormConsentLogoPath).delete()
        getConsentForm()
      });
    });
  }

  //criando circulo de progresso
  const trackUpload = (upload) => {
    return new Promise((resolve, reject) => {
      upload.on(
        "state_changed",
        (snapShot) => {
          console.log(
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100 + "%"
          );
          setConsentFormStatusChangeLogo((snapShot.bytesTransferred / snapShot.totalBytes) * 100)
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          console.log("Logo alterda com sucesso");
          setDialogStatusChangeLogo(false)
          setConsentFormStatusChangeLogo("")
          resolve();
        }
      );
    });
  }

  //pegando tipos de solicitação
  const getSolicitationTypes = () => {
    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      db.collection("solicitationTypes").where("in_company", "==", company).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listaSolicitationTypes.push({
            id: doc.id,
            type: doc.data().type
          });
        })
        setDataSolicitationTypesList(listaSolicitationTypes);
        setNewSolicitationTypesList(listaSolicitationTypes)
      })
    } else {
      db.collection("solicitationTypes").where("in_company", "==", getFkGompanyGroup.fk_company_group).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          listaSolicitationTypes.push({
            id: doc.id,
            type: doc.data().type
          });
        })
        setDataSolicitationTypesList(listaSolicitationTypes);
        setNewSolicitationTypesList(listaSolicitationTypes)
      })
    }
  }

  //paginação da tebela dos tipos de solicitação
  const filterPageSolicitation = (solicitation, index) => {
    let max = activePageSolicitation * 6;
    let min = max - 6;

    if (index >= min && index < max) {
      return solicitation;
    }
  }

  //mudando de pagina de exibição da lista de solicitações
  const handleDisplayChangePageSolicitation = (event, page) => {
    setActivePageSolicitation(page)
  }

  //registrando novos tipos de solicitação
  const handleRegisterSolicitationTypes = () => {
    // se props.parameterizationType == true, pega os dados da companhia
    if (parameterizationTypeState) {
      if (newSolicitationTypes !== '') {
        db.collection("solicitationTypes").doc().set({
          type: newSolicitationTypes,
          in_company: company,
        })
          .then(() => {
            setNewSolicitationTypes("")
            getSolicitationTypes()
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    } else {
      if (newSolicitationTypes !== '') {
        db.collection("solicitationTypes").doc().set({
          type: newSolicitationTypes,
          in_company: getFkGompanyGroup.fk_company_group,
        })
          .then(() => {
            setNewSolicitationTypes("")
            getSolicitationTypes()
          })
          .catch((error) => {
            console.error("Error ao cadastrar tipo de solicitação: ", error);
          });
      }
    }
  }

  //abrindo dialogo de atualização do tipo de solicitação
  const handleOpenUpdateDialogSolicitationTypes = (item) => {
    setSolicitationSelectedId(item.id)
    setSolicitationSelectedText(item.type)
    setDialogUpdateSolicitationType(true)
  }

  //função para atualizar titular
  const handleUpdateSolicitationButtonClick = () => {
    db.collection("solicitationTypes").doc(solicitationSelectedId).update({
      type: solicitationSelectedText
    }).then(() => {
      setSolicitationSelectedId("")
      setSolicitationSelectedText("")
      setDialogUpdateSolicitationType(false)
      getSolicitationTypes()
    })
  }

  //abrindo dialogo para excluir tipo de solicitação
  const handleOpenDeleteSolicitationDialog = (id) => {
    setSolicitationSelectedId(id)
    setDialogDeleteSolicitationType(true)
  }

  //função para excluir um tipo de solicitação
  const handleDeleteSolicitationButtonClick = () => {
    db.collection("solicitationTypes").doc(solicitationSelectedId).delete().then(() => {
      setSolicitationSelectedId("")
      setDialogDeleteSolicitationType(false)
      getSolicitationTypes()
    })
  }

  // Filtrando por tipo de solicitação
  const handleFilterSolicitationType = (item) => {
    if (requestTypeSearch !== '') {
      return item.type.toUpperCase().includes(requestTypeSearch.toUpperCase());
    } else {
      return item;
    }
  }

  //alterando data limite
  const handleUpdateExpectedDate = () => {
    db.collection("solicitationParameters").doc(consentFormId).update({
      expectedDate: getExpectedDate,
    }).then(() => {
      getConsentForm()
    })
  }

  //função para atualizar texto introdutório
  const handleUpdateIntroductoryTextt = () => {
    db.collection("solicitationParameters").doc(consentFormId).update({
      introductoryText: getIntroductoryText
    }).then(() => {
      setDialogUpdateIntroductoryText(true)
    });
  }

  //alterando link de privacidade
  const handleUpdatePrivacyPolicyUrl = () => {
    db.collection("solicitationParameters").doc(consentFormId).update({
      privacyPolicyUrl: getPrivacyPolicyUrl,
    }).then(() => {
      getConsentForm()
    })
  }

  //alterando link central de atendimento
  const handleUpdateCallCenterLink = () => {
    db.collection("solicitationParameters").doc(consentFormId).update({
      callCenter: callCenterLink,
    }).then(() => {
      getConsentForm()
    })
  }

  return (
    validation === false ?
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{
          height: '100%'
        }}
      >
        <CircularProgress size={50} color={theme.secondary_color} />
      </Grid>
      :
      <Grid
        container
        item
        justifyContent="flex-start"
        style={{
          paddingBottom: 200
        }}
      >
        <Title>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="text"
                style={{
                  fontSize: "15px",
                }}
                onClick={() => dispatch(changePage(25))}
              >
                <ArrowBackIosTwoTone />
              </Button>
            </Grid>
            <Grid item>Canal do titular {props.parameterizationType ? props.companyName : 'Grupo'}</Grid>
          </Grid>
        </Title>

        <Grid
          item
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignContent="stretch"
            style={{ width: "100%" }}
          >
            <Grid item>
              <UrlConfig type={parameterizationTypeState} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
            
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Cadastro de titular</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="row"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Cadastre um novo titular"
                  variant="outlined"
                  style={{
                    width: "30%",
                    marginRight: '10px'
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleRegisterHolder}
                  disabled={name === '' ? true : false}
                >
                  Cadastrar
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
            
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Lista de titulares</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                item
                style={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  style={{
                    padding: '10px'
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Digite um nome para pesquisar"
                    variant="outlined"
                    style={{
                      width: "30%",
                      marginRight: '10px'
                    }}
                    value={stateNameHolder}
                    onChange={(e) => setStateNameHolder(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#38f205'
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>

                <TableContainer
                  style={{
                    maxHeight: '80vh'
                  }}
                >
                  <Table
                    stickyHeader
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'Bold'
                          }}
                          onClick={
                            () => handleSortAlphabeticalOrder('name')
                          }
                        >
                          Titular {filterName ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'Bold'
                          }}
                        >
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        newListHolders
                          .filter(handleFilterHolder)
                          .filter(filterPage)
                          .map((item, k) => (
                            <TableRow
                              key={k}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold'
                                }}
                              >
                                {item.name}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold'
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: '#192a40',
                                    margin: '10px'
                                  }}
                                  onClick={() => handleOpanUpdateDialog(item)}
                                >
                                  Editar
                                </Button>
                                -
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: '#D11A2A',
                                    margin: '10px'
                                  }}
                                  onClick={() => handleOpenDeleteDialog(item.id)}
                                >
                                  EXCLUIR
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                      }
                      {
                        <Grid item>
                          {newListHolders.length >
                            7 ? (
                            <Pagination
                              count={
                                newListHolders
                                  .length %
                                  6 ===
                                  0
                                  ? newListHolders.length / 6
                                  : Math.floor(
                                    newListHolders.length / 6
                                  ) + 1
                              }
                              style={{ color: theme.secondary_color }}
                              page={activePage}
                              onChange={(event, page) => {
                                handleDisplayChangePage(event, page);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Declaração de autenticidade</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="column"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  variant="outlined"
                  value={consentFormConsentText}
                  label={consentFormConsentText ? '' : 'Sem declaração de autenticidade'}
                  multiline
                  rows={5}
                  onChange={(e) => setConsentFormConsentText(e.target.value)}
                  style={{
                    width: "40%",
                    marginRight: '10px',
                    marginBottom: '10px'
                  }}

                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleUpdateConsentText}
                >
                  <Typography
                    style={{
                      color: theme.secondary_color
                    }}
                  >
                    Alterar  Declaração de autenticidade
                  </Typography>
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography > Logo </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="column"
                style={{
                  padding: '10px'
                }}
              >
                <Button
                  variant="contained"
                  onClick={focusfileInput}
                  style={{
                    backgroundColor: '#38f205'
                  }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInput}
                    onChange={(e) => handleSelected(e)}
                  />
                  <Typography
                    style={{
                      color: theme.secondary_color
                    }}
                  >
                    Selecionar logo
                  </Typography>
                </Button>

                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    padding: '10px'
                  }}
                >
                  <ImageList
                    cols={6}
                    style={{
                      margin: '10px 0px 0px 0px',
                    }}
                  >
                    <ImageListItem
                      style={{
                        width: '300px',
                        height: '300px'
                      }}
                    >
                      <img
                        src={consentFormConsentLogo}
                        style={{
                          width: '100%'
                        }}
                      />
                    </ImageListItem>
                    <ArrowForwardIcon style={{ marginTop: '5%' }} />
                    <ImageListItem
                      style={{
                        width: '300px',
                        height: '300px',
                      }}
                    >
                      <img
                        src={imgTeste}
                        style={{
                          width: '100%'
                        }}
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleLogoChange}
                >
                  <Typography
                    style={{
                      color: theme.secondary_color
                    }}
                  >
                    Alterar logo
                  </Typography>
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography > Cadastro tipos de solicitação </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="row"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Cadastre novo tipo de solicitação"
                  variant="outlined"
                  value={newSolicitationTypes}
                  onChange={(e) => setNewSolicitationTypes(e.target.value)}
                  style={{
                    width: "30%",
                    marginRight: '10px'
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleRegisterSolicitationTypes}
                  disabled={newSolicitationTypes === '' ? true : false}
                >
                  Cadastrar
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Tipos de solicitação</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                item
                style={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  style={{
                    padding: '10px'
                  }}
                >
                  <TextField
                    label="Pesquisar solicitação"
                    variant="outlined"
                    style={{
                      width: "30%",
                      marginRight: '10px'
                    }}
                    value={requestTypeSearch}
                    onChange={(e) => setRequestTypeSearch(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#38f205'
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>

                <TableContainer
                  style={{
                    maxHeight: '80vh'
                  }}
                >
                  <Table
                    stickyHeader
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'Bold'
                          }}
                          onClick={
                            () => handleSortAlphabeticalOrder('solicitationType')
                          }
                        >
                          Tipo de solicitação {filterSolicitationTypesFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: 'Bold'
                          }}
                        >
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        newSolicitationTypesList
                          .filter(handleFilterSolicitationType)
                          .filter(filterPageSolicitation)
                          .map((item, k) => (
                            <TableRow
                              key={k}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={k % 2 ? { background: "white" } : { background: "#e3e3e3" }}
                            >
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold'
                                }}
                              >
                                {item.type}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontWeight: 'Bold'
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: '#192a40',
                                    margin: '10px'
                                  }}
                                  onClick={() => handleOpenUpdateDialogSolicitationTypes(item)}
                                >
                                  Editar
                                </Button>
                                -
                                <Button
                                  variant="contained"
                                  style={{
                                    color: '#fff',
                                    backgroundColor: '#D11A2A',
                                    margin: '10px'
                                  }}
                                  onClick={() => handleOpenDeleteSolicitationDialog(item.id)}
                                >
                                  EXCLUIR
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                      }
                      {
                        <Grid item>
                          {newSolicitationTypesList.length >
                            7 ? (
                            <Pagination
                              count={
                                newSolicitationTypesList
                                  .length %
                                  6 ===
                                  0
                                  ? newSolicitationTypesList.length / 6
                                  : Math.floor(
                                    newSolicitationTypesList.length / 6
                                  ) + 1
                              }
                              style={{ color: theme.secondary_color }}
                              page={activePage}
                              onChange={(event, page) => {
                                handleDisplayChangePageSolicitation(event, page);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Definir data prevista</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="row"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  label={getExpectedDate ? '' : "Definir data prevista"}
                  variant="outlined"
                  style={{
                    width: getExpectedDate ? '5%' : "15%",
                    marginRight: '10px'
                  }}
                  value={getExpectedDate}
                  onChange={(e) => setExpectedDate(e.target.value)}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleUpdateExpectedDate}
                  disabled={getExpectedDate === '' ? true : false}
                >
                  Definir data limite
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Texto introdutório</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="column"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  variant="outlined"
                  value={getIntroductoryText}
                  label={getIntroductoryText ? '' : 'Sem texto introdutório'}
                  multiline
                  rows={5}
                  onChange={(e) => setIntroductoryText(e.target.value)}
                  style={{
                    width: "40%",
                    marginRight: '10px',
                    marginBottom: '10px'
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleUpdateIntroductoryTextt}
                >
                  <Typography
                    style={{
                      color: theme.secondary_color
                    }}
                  >
                    Alterar  texto introdutório
                  </Typography>
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Link de privacidade</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="row"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  label={getPrivacyPolicyUrl ? '' : "Definir link de privacidade"}
                  variant="outlined"
                  style={{
                    marginRight: '10px'
                  }}
                  value={getPrivacyPolicyUrl}
                  onChange={(e) => setPrivacyPolicyUrl(e.target.value)}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleUpdatePrivacyPolicyUrl}
                  disabled={getPrivacyPolicyUrl === '' ? true : false}
                >
                  Alterar link de privacidade
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          container
          item
          direction="row"
          style={{
            padding: '10px'
          }}
        >
          <Accordion
          
            style={{
              width: '100%'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography >Link de Central de Atendimento</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                item
                direction="row"
                style={{
                  padding: '10px'
                }}
              >
                <TextField
                  label={getPrivacyPolicyUrl ? '' : "Insira o link"}
                  variant="outlined"
                  style={{
                    marginRight: '10px'
                  }}
                  value={callCenterLink}
                  onChange={(e) => setCallCenterLink(e.target.value)}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#38f205'
                  }}
                  onClick={handleUpdateCallCenterLink}
                  disabled={setCallCenterLink === '' ? true : false}
                >
                  Alterar link Central de Atendimento
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid
          item
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignContent="stretch"
            style={{ width: "100%" }}
          >
            <Grid item>
              <SmtpConfig type={parameterizationTypeState} />
            </Grid>
          </Grid>
        </Grid>

        {/* Dialogo de exclusão do titular */}
        <Dialog open={dialogDelete} onClose={() => setDialogDelete(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            ATENÇÃO!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  VOCÊ ESTA PRESTES A EXCLUIR UM TITULAR!
                  <br />
                  TEM CERTEZA DESTA AÇÃO?
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogDelete(false)}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDeleteButtonClick()}
              style={{
                color: '#fff',
                backgroundColor: theme.warning_color,
              }}
            >
              Deletar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialogo de edição do titular */}
        <Dialog open={dialogUpdate} onClose={() => setDialogUpdate(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            EDITAR TITULAR
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  <TextField
                    label="Atualizar titular"
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginRight: '10px'
                    }}
                    value={holderName}
                    onChange={(e) => setHolderName(e.target.value)}
                  />
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogUpdate(false)}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleUpdateButtonClick()}
              style={{
                color: '#fff',
                backgroundColor: theme.primary_color,
              }}
            >
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para informar a atualização do texto de consentimento */}
        <Dialog open={dialogUpdateConsentText} onClose={() => setDialogUpdateConsentText(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            Aviso!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  <Typography
                    variant="h5"
                  >
                    Declaração de autenticidade atualizada com sucesso!
                  </Typography>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: theme.primary_color
              }}
              onClick={() => setDialogUpdateConsentText(false)}
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para informar a atualização do texto de consentimento */}
        <Dialog open={dialogStatusChangeLogo} onClose={() => setDialogStatusChangeLogo(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h5"
            >
              Progresso de atualização
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%", height: "40px" }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={consentFormStatusChangeLogo}
                    />
                  </Grid>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Dialogo de edição do tipo de solicitação */}
        <Dialog open={dialogUpdateSolicitationType} onClose={() => setDialogUpdateSolicitationType(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            EDITAR
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  <TextField
                    label="Atualizar tipo de solicitação"
                    variant="outlined"
                    style={{
                      width: "100%",
                      marginRight: '10px'
                    }}
                    value={solicitationSelectedText}
                    onChange={(e) => setSolicitationSelectedText(e.target.value)}
                  />
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogUpdateSolicitationType(false)}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleUpdateSolicitationButtonClick()}
              style={{
                color: '#fff',
                backgroundColor: theme.primary_color,
              }}
            >
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialogo para excluir tipo de solicitação */}
        <Dialog open={dialogDeleteSolicitationType} onClose={() => setDialogDeleteSolicitationType(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            ATENÇÃO!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  VOCÊ ESTA PRESTES A EXCLUIR UM TIPO DE SOLICITAÇÃO
                  <br />
                  TEM CERTEZA DESTA AÇÃO?
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogDeleteSolicitationType(false)}
              autoFocus
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleDeleteSolicitationButtonClick()}
              style={{
                color: '#fff',
                backgroundColor: theme.warning_color,
              }}
            >
              Deletar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialogo para informar que 
					o tamanho da imagem da logo 
					não pode ultrapassar 3mb 
				*/}
        <Dialog open={dialogWarningImageSize} onClose={() => setDialogWarningImageSize(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            ATENÇÃO!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  O tamanho da imagem não pode ser superior a 3MB
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDialogWarningImageSize(false)}
              autoFocus
            >
              Entendi!
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para informar a atualização do texto introdutório */}
        <Dialog open={dialogUpdateIntroductoryText} onClose={() => setDialogUpdateIntroductoryText(false)}>
          <DialogTitle
            style={{
              color: theme.quaternary_color,
              backgroundColor: theme.secondary_color,
              textAlign: "center",
            }}
          >
            Aviso!
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                textAlign="center"
              >
                <DialogContentText>
                  <Typography
                    variant="h5"
                  >
                    Texto introdutório atualizado com sucesso!
                  </Typography>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: theme.primary_color
              }}
              onClick={() => setDialogUpdateIntroductoryText(false)}
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    parameterizationType: state.parameterizationReducer.parameterizationType,
    companyName: state.parameterizationReducer.companyName
  };
}

export default connect(mapStateToProps, {})(ParameterHolders);