import React, { useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import myApp from '../../core/infrastructure/firebaseConfig'
import Countdown from 'react-countdown'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import CheckIcon from '@material-ui/icons/Check'

const sendVerificationEmail = myApp
  .functions()
  .httpsCallable('sendVerificationEmail')

const database = myApp.firestore()

const useStyles = makeStyles((theme) => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    marginBottom: '10px',
  },
}))

const SendVerificationEmailButton = ({ sendTo, company, onEmailVerified }) => {
  const classes = useStyles()
  const [waitToSendEmailVerification, setWaitToSendEmailVerification] =
    useState(false)
  const [email, setEmail] = useState({})
  const [isCountDownDone, setIsCountDownDone] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [mounted, setMounted] = useState(true)
  const [firstEmailSended, setFirstEmailSended] = useState(false)
  const [manualEmailSended, setManualEmailSended] = useState(false)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: manualEmailSended,
  })

  const sendEmailVerification = useCallback(async () => {
    try {
      const email = {
        email: sendTo,
        createDate: new Date(Date.now()),
        isVerified: false,
      }
      const resp = await sendVerificationEmail({ email: email.email, company })
      email.id = resp.data.id
      return email
    } catch (error) {
      console.log(error)
    }
  }, [sendTo])

  const renderText = () => {
    if (waitToSendEmailVerification) {
      return 'enviando...'
    } else {
      if (manualEmailSended) {
        return 'enviado'
      }
    }

    return 'Enviar e-mail de verificação'
  }

  const renderCounter = () => {
    if (!waitToSendEmailVerification && manualEmailSended) {
      return (
        <Countdown
          onComplete={timer => {
            setIsCountDownDone(true)
            setWaitToSendEmailVerification(false)
            setManualEmailSended(false)
          }}
          date={Date.now() + 60000}
          renderer={({ hours, minutes, seconds, completed }) => {
            return (
              <span>
                {seconds > 0 ? <>Aguarde {seconds} segundos antes de enviar outra solicitação.</> : null}
              </span>
            )
          }}
        />
      )
    }
  }

  const checkIfHasVerified = () => {
    return setTimeout(async function () {
      let resp = await database
        .collection('emails')
        .doc(email.id)
        .get()

      if (resp.data().isVerified) {
        setIsEmailVerified(true)
      } else {
        checkIfHasVerified()
      }
    }, 3000)
  }

  useEffect(() => {
    if (mounted) {
      if (!firstEmailSended && !manualEmailSended) {
        sendEmailVerification().then(resp => setEmail(resp))
        setFirstEmailSended(true)
      }
      if (email?.email) {
        checkIfHasVerified()
      }
    }
    return () => {}
  }, [mounted, firstEmailSended, email, manualEmailSended])

  useEffect(() => {
    if (isCountDownDone) {
      setEmail({})
      setIsCountDownDone(false)
    }
  }, [isCountDownDone])

  useEffect(() => {
    if (isEmailVerified) onEmailVerified()
  }, [isEmailVerified, onEmailVerified])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Button
          variant="contained"
          type="button"
          className={buttonClassname}
          color="primary"
          startIcon={
            waitToSendEmailVerification ? (
              <CircularProgress/>
            ) : (manualEmailSended && !isCountDownDone) ? (
              <CheckIcon/>
            ) : (
              <EmailIcon/>
            )
          }
          disabled={waitToSendEmailVerification}
          onClick={(evt) => {
            if (waitToSendEmailVerification || manualEmailSended)
              return
            setWaitToSendEmailVerification(true)

            sendEmailVerification().then((res) => {
              setEmail(res)
              setManualEmailSended(true)
              setWaitToSendEmailVerification(false)
            })
          }}
        >
          {renderText()}
        </Button>
      </Grid>
      {
        !waitToSendEmailVerification && manualEmailSended ?
          <Grid item>{renderCounter()}</Grid>
          : null
      }
    </Grid>
  )
}

export default SendVerificationEmailButton
