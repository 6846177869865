import React, { Component } from "react";
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/firestore";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  ListItemAvatar,
  Hidden,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Typography,
  ListItemIcon,
  Collapse,
  withWidth,
  isWidthUp,
  Tooltip
} from "@material-ui/core";
import { AddCircle, Close, ExpandLess, ExpandMore, Forum, Videocam } from "@material-ui/icons";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MenuIcon from "@material-ui/icons/Menu";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { loginListener } from "../../../login/commands/loginListener";
import { logout } from "../../../login/commands/logout";
import { getUserInformation } from "../../command/getUserInformation";
import GroupIcon from "@material-ui/icons/Group";
import "./dashboard.css";
import componentList from "../componentList";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { checkUser } from "../../../login/commands/checkUser";
import routerHistory from "../../../core/infrastructure/routerHistory";
import { connect, useDispatch } from "react-redux";
import { changePage } from "../redux/actions";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import GavelIcon from '@material-ui/icons/Gavel';
import Button from "@material-ui/core/Button";
import dashboardLogo from "../../../res/images/dashboard_logo.png";
import { getPermitions } from "../componentList";
import theme from "../../../core/theme.json";
import DrawerComponent from "../../features/parameterization/components/DrawerComponent";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.db = myApp.firestore();

    this.state = {
      userInformation: null,
      mobileDrawer: false,
      menu: false,
      reports: false,
      selectedComponent: 0,
      drawerOpen: true,
      companies: [],
      currentCompany: "",
      reports: false,
      schedule:false,
      communication: false,
      legislation:false,
      discovery: false,
      training:false,
      mapping:false,
      open: false,
      toltipCompany:true,
      PageList:[]
    };
  }

  componentDidMount() {
    loginListener();
    var self = this;
     this.handlePermitions()
    checkUser(function () {
      self._getUserInformation();
    });

    this.getCompanies();
  }

  async handlePermitions(){
    const Permitions = await getPermitions()

    this.setState({
      PageList:Permitions
    })
   

  }


  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.selectComponent(this.props.page);
    }
  }

  async _getUserInformation() {
    this.setState({
      userInformation: await getUserInformation(),
    });
  }
  handleDrawerClose = () => {
    this.setState({
      mobileDrawer: false,
    });

    this.setState({ reports: false });
  };

  handleDrawer() {
    this.setState({
      mobileDrawer: !this.state.mobileDrawer,
    });
  }
handleMenu
  handleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  handleClick() {
    this.setState({
      reports: !this.state.reports,
    });
  }
  handleClickLegislation() {
    this.setState({
      legislation: !this.state.legislation,
    });
  }
  handleClickSchedule() {
    this.setState({
      schedule: !this.state.schedule,
    });
  }
  handleClickMapping
  handleClickMapping() {
    this.setState({
      mapping: !this.state.mapping,
    });
  }

  handleClickComunication() {
    this.setState({
      communication: !this.state.communication,
    });
  }

  handleClickTraining = () => {
    this.setState({
      training: !this.state.training,
    });
  };
  
  handleClickDiscovery() {
    this.setState({
      discovery: !this.state.discovery,
    });
  }
  handleDrawerOpen() {
    this.setState({
      open: true,
    });
  }

  selectComponent(index) {
    this.setState(
      {
        selectedComponent: index,
        mobileDrawer: false,
      },
      () => {
        this.handleReducer();
      }
    );
  }

  handleReducer() {
    this.props.changePage(this.state.selectedComponent);
  }

  async getCompanies() {
    let database = myApp.firestore();
    let companyGroup = localStorage.getItem("@blue-legal/companyGroup");
    var _company = localStorage.getItem("currentCompany");
    let self = this;

    try {
      let data = await database
        .collection("companies")
        .where("active", "==", true)
        .where("fk_company_group", "==", companyGroup)
        .get();
      let _companies = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      if (!_companies.some((c) => c.id === _company)) {
        localStorage.setItem("currentCompany", "");
        routerHistory.push("/company");
      }

      self.setState({
        companies: _companies,
        currentCompany: _company,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleCompany(event) {
    this.setState({
      currentCompany: event.target.value,
    });

    localStorage.setItem("currentCompany", event.target.value);

    routerHistory.push("/company");
  }

  render() {
    if (this.state.userInformation !== null) {
      sessionStorage.setItem(
        "@blue-legal/userInformation",
        JSON.stringify(this.state.userInformation)
      );
      if (!this.state.PageList[this.state.selectedComponent]?.fullScreen) {
        return (
          <div className="root">
            <CssBaseline />
            <AppBar
              position="fixed"
              className="appBar"
              style={{ background: theme.secondary_color }}
              elevation={0}
            >
              <Toolbar style={{ padding: "0px" }}>
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    edge="start"
                    onClick={() => this.handleDrawer()}
                    className="menuButton"
                    style={{ color: theme.primary_color, paddingLeft: "24px" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div className="spacer" />
                  {/* <div style={{ paddingRight: "16px" }}>
                    <img alt='logo' src={localStorage.getItem("userPhoto")} style={{ height: "42px", paddingTop: "4px" }} />
                  </div> */}
                </Hidden>
                <Hidden mdDown>
                  {/* base dropdown */}
                  <div
                    style={{
                      width: "264px",
                      background: theme.secondary_color,
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ paddingRight: "10px" }}>
                        {this.state.userInformation ? (
                          // <Avatar alt="Remy Sharp" src={this.state.userInformation.photoURL} />
                          <Avatar
                            alt="Remy Sharp"
                            src={localStorage.getItem("userPhoto")}
                          />
                        ) : (
                          <Avatar>
                            <CircularProgress className="avatarLoadingCircle" />
                          </Avatar>
                        )}
                      </div>
                   
                      <FormControl
                        style={{ display: "contents", alignItems: "center" }}
                      >
                             <Select
                          style={{ color: "white", width: "184px" }}
                          value={this.state.currentCompany}
                          onChange={(event) => {
                            this.handleCompany(event);
                          }}
                        >
                          {this.state.companies.map((company) => (
                            <MenuItem
                              style={{ color: "#111C39" }}
                              value={company.id}
                            >
                              {company.nickname}
                            </MenuItem>
                          ))}

                          <div
                            style={{
                              height: "2px",
                              listStylePosition: "inside",
                              borderTop: `1px dashed ${theme.primary_color}`,
                              margin: "4px",
                            }}
                          />

                          <MenuItem
                            style={{ color: theme.secondary_color }}
                            value={""}
                          >
                            Voltar
                          </MenuItem>
                        </Select>

                      </FormControl>
                     
                    </div>
                  </div>
                  <div className="spacer" />
                </Hidden>

                
                <Tooltip title="Configurações">
                <IconButton aria-label="Configurações">
                <SettingsIcon
                  style={{ cursor: "pointer", color:"#fff"}}
                  onClick={() => {
                    this.handleMenu();
                  }}
                />
                </IconButton>
              </Tooltip>
                <div
                  style={
                    window.screen.width <= 1115
                      ? { marginRight: "2%" }
                      : { width: "10%" }
                  }
                >
                  <ListItem button onClick={() => logout()}>
                    <div style={{ width: "30%" }} />
                    {window.screen.width >= 350 && (
                      <ListItemText primary="sair" />
                    )}
                  </ListItem>
                </div>
              </Toolbar>
            </AppBar>

            <Dialog
              onClose={() => {
                this.handleMenu();
              }}
              open={this.state.menu}
            >
              <DialogTitle>
                <Grid
                  xs="12"
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item>
                    <Typography>Configurações</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => this.handleMenu()}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <List>
                <ListItem
                  button
                  onClick={() => routerHistory.push("/usercontrol")}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <GroupIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Controle de usuários" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    this.props.changePage(25);
                    this.handleMenu();
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <SettingsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Parametrização" />
                </ListItem>
              </List>
            </Dialog>

            <nav className="drawer">
              <Hidden lgUp>
                <Drawer
                  variant="temporary"
                  open={this.state.mobileDrawer}
                  onClose={() => this.handleDrawer()}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <div
                    style={{
                      width: "264px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        className="menuButton"
                        onClick={() => {
                          this.handleMenu();
                        }}
                      >
                        {this.state.userInformation ? (
                          // <Avatar alt="Remy Sharp" src={this.state.userInformation.photoURL} />
                          <Avatar
                            alt="Remy Sharp"
                            src={localStorage.getItem("userPhoto")}
                          />
                        ) : (
                          <Avatar>
                            <CircularProgress className="avatarLoadingCircle" />
                          </Avatar>
                        )}
                      </IconButton>
                      <FormControl
                        style={{ display: "contents", alignItems: "center" }}
                      >
                        <Select
                          style={{ color: "white", width: "184px" }}
                          value={this.state.currentCompany}
                          onChange={(event) => {
                            this.handleCompany(event);
                          }}
                        >
                          {this.state.companies.map((company) => (
                            <MenuItem
                              style={{ color: "#111C39" }}
                              value={company.id}
                            >
                              {company.nickname}
                            </MenuItem>
                          ))}
                          <div
                            style={{
                              height: "2px",
                              listStylePosition: "inside",
                              borderTop: "1px dashed #F26B36",
                              margin: "4px",
                            }}
                          />
                          <MenuItem style={{ color: "#111C39" }} value={""}>
                            Voltar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <List>
                    {this.state.PageList?.map((component, index) =>
                      component.showOnSideBar ||
                      (component.name === "Treinamento" &&
                        this.state.currentCompany ===
                          "DfrkIyi0Jrrn9XFDDIrh") ? (
                        <ListItem
                          button
                          onClick={() => this.selectComponent(index)}
                        >
                          <component.icon
                            style={
                              this.state.selectedComponent === index
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ width: "10px" }} />
                          <ListItemText
                            primary={component.name}
                            style={
                              this.state.selectedComponent === index
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                        </ListItem>
                      ) : (
                        <div />
                      )
                    )}
                    <ListItem
                      button
                      onClick={() => {
                        this.handleClick();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <LibraryBooksIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Relatórios"
                        style={{ color: "white" }}
                      />

                      {this.state.reports ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.reports}
                      timeout="auto"
                      unmountOnExit
                    >
                       <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 9 });
                            useDispatch(changePage(9));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 9
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Relatorio de respostas"
                              style={
                                this.state.selectedComponent === 9
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 8 });
                            useDispatch(changePage(8));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 8
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Relatórios de gaps"
                              style={
                                this.state.selectedComponent === 8
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                     
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 19 });
                            useDispatch(changePage(19));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 19
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Relatórios ROPA"
                              style={
                                this.state.selectedComponent === 19
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 21 });
                            useDispatch(changePage(21));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 21
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Relatórios DPIA"
                              style={
                                this.state.selectedComponent === 21
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 22 });
                            useDispatch(changePage(22));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 22
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Relatórios LIA"
                              style={
                                this.state.selectedComponent === 22
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                     
                    </Collapse>

                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickSchedule();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <CardTravelIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Agenda"
                        style={{ color: "white" }}
                      />

                      {this.state.schedule ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.schedule}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 12 });
                            useDispatch(changePage(12));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 12
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Cronograma"
                              style={
                                this.state.selectedComponent === 12
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                   
                    </Collapse>

                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickLegislation();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <CardTravelIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Compliance"
                        style={{ color: "white" }}
                      />

                      {this.state.legislation ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.legislation}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 14 });
                            useDispatch(changePage(14));
                          }}
                        >
                          <CardTravelIcon
                            style={
                              this.state.selectedComponent === 14
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Bases Legais/Legislação"
                              style={
                                this.state.selectedComponent === 14
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 15 });
                            useDispatch(changePage(15));
                          }}
                        >
                          <AssignmentIcon
                            style={
                              this.state.selectedComponent === 15
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Políticas e Planos"
                              style={
                                this.state.selectedComponent === 15
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                    </Collapse>
               
                       
                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickTraining();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <PersonalVideoIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Treinamneto"
                        style={{ color: "white" }}
                      />

                      {this.state.training ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.training}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 27 });
                            useDispatch(changePage(27));
                          }}
                        >
                          <Videocam
                            style={
                              this.state.selectedComponent === 27
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Vídeos"
                              style={
                                this.state.selectedComponent === 27
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 36 });
                            useDispatch(changePage(36));
                          }}
                        >
                          <AddCircle
                            style={
                              this.state.selectedComponent === 36
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Criar Novo Treinamento"
                              style={
                                this.state.selectedComponent === 34
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                    </Collapse>
                  
                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickDiscovery();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <LibraryBooksIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Discovery"
                        style={{ color: "white" }}
                      />

                      {this.state.discovery ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.discovery}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 23 });
                            useDispatch(changePage(23));
                          }}
                        >
                          <CardTravelIcon
                            style={
                              this.state.selectedComponent === 23
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Discovery - Arquivos"
                              style={
                                this.state.selectedComponent === 23
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 24 });
                            useDispatch(changePage(24));
                          }}
                        >
                          <CardTravelIcon
                            style={
                              this.state.selectedComponent === 24
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Discovery - BD"
                              style={
                                this.state.selectedComponent === 24
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                    </Collapse>

                     
                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickMapping();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <AccountTreeIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Mapeamento"
                        style={{ color: "white" }}
                      />

                      {this.state.mapping ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.mapping}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 5 });
                            useDispatch(changePage(5));
                          }}
                        >
                          <SettingsIcon
                            style={
                              this.state.selectedComponent === 5
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Processos"
                              style={
                                this.state.selectedComponent === 5
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 3 });
                            useDispatch(changePage(3));
                          }}
                        >
                          <Forum
                            style={
                              this.state.selectedComponent === 3
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Questionários"
                              style={
                                this.state.selectedComponent === 3
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 10 });
                            useDispatch(changePage(10));
                          }}
                        >
                          <Forum
                            style={
                              this.state.selectedComponent === 10
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Teste LIA"
                              style={
                                this.state.selectedComponent === 10
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                    </Collapse>

                    
                    <ListItem
                      button
                      onClick={() => {
                        this.handleClickComunication();
                        this.handleDrawerOpen();
                      }}
                    >
                      <ListItemIcon>
                        <LibraryBooksIcon style={{ color: "white" }} />
                      </ListItemIcon>

                      <ListItemText
                        primary="Canais de Comunicação"
                        style={{ color: "white" }}
                      />

                      {this.state.communication ? (
                        <ExpandLess style={{ color: "white" }} />
                      ) : (
                        <ExpandMore style={{ color: "white" }} />
                      )}
                    </ListItem>
                    <Collapse
                      in={this.state.communication}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 16 });
                            useDispatch(changePage(16));
                          }}
                        >
                          <LibraryBooksIcon
                            style={
                              this.state.selectedComponent === 16
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Canal de Solicitações"
                              style={
                                this.state.selectedComponent === 16
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      <List component="div" disablePadding>
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 20 });
                            useDispatch(changePage(20));
                          }}
                        >
                          <CardTravelIcon
                            style={
                              this.state.selectedComponent === 20
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Comunicação de incidentes"
                              style={
                                this.state.selectedComponent === 20
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      
                      <List component="div" >
                        <ListItem
                          style={{ width: "100%" }}
                          button
                          onClick={() => {
                            this.handleDrawerClose();
                            this.setState({ selectedComponent: 42 });
                            useDispatch(changePage(42));
                          }}
                        >
                          <GavelIcon
                            style={
                              this.state.selectedComponent === 42
                                ? { color: theme.primary_color }
                                : { color: "white" }
                            }
                          />
                          <div style={{ marginLeft: "5%" }}>
                            <ListItemText
                              primary="Canal de Denúncia"
                              style={
                                this.state.selectedComponent === 42
                                  ? { color: theme.primary_color }
                                  : { color: "white" }
                              }
                            ></ListItemText>
                          </div>
                        </ListItem>
                      </List>
                      
                      
                    </Collapse>


                  </List>
                </Drawer>
              </Hidden>
              
              <Hidden mdDown>
                <Drawer
                  className="drawer"
                  variant="permanent"
                  classes={{
                    paper: "drawerPaper",
                  }}
                >
                  <Toolbar />
                  <div className="drawerContainer">
                    <List>
                      <DrawerComponent
                        drawerOpen={() => this.setState({ drawerOpen: true })}
                        drawerClose={() => this.setState({ drawerOpen: false })}
                      />
                    </List>
                  </div>
                </Drawer>
              </Hidden>
            </nav>
            <div 
              className={`content ${
                isWidthUp('lg', this.props.width) && (this.state.drawerOpen == true  
                  ? "contentPadding20" 
                  : "contentPadding")
              }`}
              style={{ height: "100%" }}
            >
              {this.state.PageList[this.state.selectedComponent]?.component}
            </div>
          </div>
        );
      } else {
        return (
          <div>{this.state.PageList[this.state.selectedComponent]?.component}</div>
        );
      }
    } else {
      return <div />;
    }
  }
}

function mapStateToProps(state) {
  return {
    page: state.dashboardReducer.page,
  };
}

export default connect(mapStateToProps, { changePage })(withWidth()(Dashboard));
