import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import { DenunceItem } from "../components/denunce";
import myApp from "../../core/infrastructure/firebaseConfig";

export const DenunceView = () => {
  const [loading, setLoading] = useState(false);
  const [dataDenunce, setDataDenunce] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemParamers, setItemParamers] = useState([]);
  const [protocolActual, setProtocolActual] = useState("");
  const [isdenunceActive, setDenunceActive] = useState(false);

  const handleDenunceprotocol = async () => {
    setLoading(true);
    if (protocolActual == "") {
      alert("campo nao pode ser vazio ");
      return setLoading(false);
    }
    setDenunceActive(true)
    try {
      let get = await myApp
        .firestore()
        .collection("denunce")
        .where("protocol", "==", protocolActual)
        .get();

      const dados = get.docs.map((item) => item.data());
      setItemData(dados);
      console.log("deu certaaaa", dados);

      setDataDenunce(true);

      let getDateParamers = await myApp
        .firestore()
        .collection("denunceParameters")
        .where("fk_company_group", "==", localStorage.getItem("currentCompany"))
        .get();
      const dateParamers = getDateParamers.docs.map((item) => item.data());
      console.log("parametros", dateParamers);
      setItemParamers(dateParamers);
      setDenunceActive(true);
    } catch (err) {
      console.log("deu erro", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {isdenunceActive == false && (
        <>
          <h1>
            Digite o número do protocolo informado na criação da denúncia :
          </h1>

          <Grid style={{ marginTop: "10%" }}>
            <TextField
              style={{ width: "300px", height: "50px" }}
              id="outlined-basic"
              label="Número do protocolo "
              type="number"
              variant="outlined"
              onChange={(e) => setProtocolActual(e.target.value)}
            />
          </Grid>

          <Grid style={{ marginTop: "10%" }}>
            <Button
              style={{ width: "300px", height: "50px" }}
              onClick={() => handleDenunceprotocol()}
              variant="contained"
              color="primary"
            >
              Visualizar
            </Button>
          </Grid>
        </>
      )}

      {loading && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          style={{ marginTop: "20%" }}
        >
          <CircularProgress size={60} color="#193993" />
        </Grid>
      )}
      {dataDenunce && <DenunceItem item={itemData} paramer={itemParamers} />}
    </Grid>
  );
};
