import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AddCircle,
  Forum,
  Home,
  RecordVoiceOver,
  Videocam,
} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import { Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import List from "@material-ui/core/List";
import GavelIcon from "@material-ui/icons/Gavel";
import CssBaseline from "@material-ui/core/CssBaseline";
import LanguageIcon from "@material-ui/icons/Language";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";
import ListDrawer from "../../../presentation/ListDrawer";
import Theme from "../../../../core/theme.json";
import "../../../presentation/pages/dashboard.css";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/actions";
import { getPermitions } from "../../../presentation/componentList";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const DrawerComponent = ({ drawerOpen, drawerClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [state, setState] = useState({
    userInformation: null,
    mobileDrawer: false,
    menu: false,
    selectedComponent: 0,
    componentList: 0,
    companies: [],
    currentCompany: "",
  });
  const [list, setList] = useState({
    reports: false,
    legislation: false,
    discovery: false,
    schedule: false,
    mapping: false,
    comunicattion: false,
    training: false,
    PageList:[]
  });

 useEffect(()=>{
  handlePermitions()
 },[])


  const handleClick = () => {
    setList({
      reports: !list.reports,
    });
  };
  const handleClickLegislation = () => {
    setList({
      legislation: !list.legislation,
    });
  };
  const handleClickSchedule = () => {
    setList({
      schedule: !list.schedule,
    });
  };

  const handleClickTraining = () => {
    setList({
      training: !list.training,
    });
  };
  
  const handleClickMapping = () => {
    setList({
      mapping: !list.mapping,
    });
  };
  const handleClickDiscovery = () => {
    setList({
      discovery: !list.discovery,
    });
  };
  const handleClickComunication = () => {
    setList({
      comunicattion: !list.comunicattion,
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);

    drawerOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    drawerClose(false);
    setList({ reports: false });
  };

  const handlePermitions = async () =>{
    const Permitions = await getPermitions()
    setList({
    PageList: Permitions
    })
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      ></AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: "drawerPaper",
        }}
      >
        <div className={classes.toolbar}></div>
        <Divider />

        {open == true && (
          <Tooltip title="Retrair">

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </IconButton>
          </Tooltip>

        )}

        {open == false && (
          <div style={{ marginLeft: "15px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <Tooltip title="Expandir">
              <MenuIcon style={{ color: "white" }} />
              </Tooltip>
            </IconButton>
          </div>
        )}
        <List>
          {ListDrawer.map((component, index) =>
            component.showOnSideBar ||
            (component.name === "Treinamento" &&
              state.currentCompany === "DfrkIyi0Jrrn9XFDDIrh") ? (
              <ListItem
                button
                onClick={() => {
                  setState({
                    selectedComponent: index,
                    mobileDrawer: false,
                  });
                  dispatch(changePage(index));
                }} 
              >
                {open == false ? (
                  <Tooltip title={component.name}>
                    <component.icon
                      style={
                        state.selectedComponent === index
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <component.icon
                    style={
                      state.selectedComponent === index 
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ width: "10px" }} />
                {open == true && (
                  <ListItemText
                    primary={component.name }
                    style={
                      state.selectedComponent === index 
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}
              </ListItem>
            ) : (
              <div />
            )
          )}
          
          <ListItem
            button
            onClick={() => {
              handleClick();
            }}
          >
            {open == false ? (
              <Tooltip title="Relatórios">
                <ListItemIcon>
                  <LibraryBooksIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <LibraryBooksIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}
            {open == true && (
              <ListItemText primary="Relatórios" style={{ color: "white" }} />
            )}
            {list.reports ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.reports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 9 });
                  dispatch(changePage(9));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatório de respostas">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 9
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 9
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}
                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatório de respostas"
                      style={
                        state.selectedComponent === 9
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 45 });
                  dispatch(changePage(45));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatório de treinamento">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 45
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 45
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}
                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatório de treinamento"
                      style={
                        state.selectedComponent === 45
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 8 });
                  dispatch(changePage(8));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatórios de gaps">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 8
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 8
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}
                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatórios de gaps"
                      style={
                        state.selectedComponent === 8
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 19 });
                  dispatch(changePage(19));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatórios ROPA">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 19
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 19
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatórios ROPA"
                      style={
                        state.selectedComponent === 19
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 21 });
                  dispatch(changePage(21));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatórios DPIA">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 21
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 21
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatórios DPIA"
                      style={
                        state.selectedComponent === 21
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 22 });
                  dispatch(changePage(22));
                }}
              >
                {open == false ? (
                  <Tooltip title="Relatórios LIA">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 22
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 22
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Relatórios LIA"
                      style={
                        state.selectedComponent === 22
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => {
              handleClickSchedule();
            }}
          >
            {open == false ? (
              <Tooltip title="Agenda">
                <ListItemIcon>
                  <DateRangeIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <DateRangeIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText primary="Agenda" style={{ color: "white" }} />
            )}
            {list.schedule ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.schedule} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 12 });
                  dispatch(changePage(12));
                }}
              >
                {open == false ? (
                  <Tooltip title="Cronograma">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 12
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 12
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Cronograma"
                      style={
                        state.selectedComponent === 12
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => {
              handleClickLegislation();
            }}
          >
            {open == false ? (
              <Tooltip title="Compliance">
                <ListItemIcon>
                  <CardTravelIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <CardTravelIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText primary="Compliance" style={{ color: "white" }} />
            )}
            {list.legislation ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.legislation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 14 });
                  dispatch(changePage(14));
                }}
              >
                {open == false ? (
                  <Tooltip title="Bases legais/legislação">
                    <CardTravelIcon
                      style={
                        state.selectedComponent === 14
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <CardTravelIcon
                    style={
                      state.selectedComponent === 14
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Bases legais/legislação"
                      style={
                        state.selectedComponent === 14
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 15 });
                  dispatch(changePage(15));
                }}
              >
                {open == false ? (
                  <Tooltip title="Políticas e planos">
                    <AssignmentIcon
                      style={
                        state.selectedComponent === 15
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <AssignmentIcon
                    style={
                      state.selectedComponent === 15
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Políticas e planos"
                      style={
                        state.selectedComponent === 15
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => {
              handleClickTraining();
            }}
          >
            {open == false ? (
              <Tooltip title="Treinamento">
                <ListItemIcon>
                  <PersonalVideoIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <PersonalVideoIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText primary="Treinamento" style={{ color: "white" }} />
            )}
            {list.training ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.training} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 27 });
                  dispatch(changePage(27));
                }}
              >
                {open == false ? (
                  <Tooltip title="Vídeos">
                    <Videocam
                      style={
                        state.selectedComponent === 27
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <Videocam
                    style={
                      state.selectedComponent === 27
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Vídeos"
                      style={
                        state.selectedComponent === 27
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 35 });
                  dispatch(changePage(35));
                }}
              >
                {open == false ? (
                  <Tooltip title="Criar novo treinamento">
                    <AddCircle
                      style={
                        state.selectedComponent === 35
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <AddCircle
                    style={
                      state.selectedComponent === 35
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Criar novo treinamento"
                      style={
                        state.selectedComponent === 35
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              handleClickDiscovery();
            }}
          >
            {open == false ? (
              <Tooltip title="Discovery">
                <ListItemIcon>
                  <LanguageIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <LanguageIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText primary="Discovery" style={{ color: "white" }} />
            )}
            {list.discovery ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.discovery} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={() => {
                  setState({ selectedComponent: 23 });
                  dispatch(changePage(23));
                }}
              >
                {open == false ? (
                  <Tooltip title="Discovery - arquivos">
                    <CardTravelIcon
                      style={
                        state.selectedComponent === 23
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <CardTravelIcon
                    style={
                      state.selectedComponent === 23
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Discovery - arquivos"
                      style={
                        state.selectedComponent === 23
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={() => {
                  setState({ selectedComponent: 24 });
                  dispatch(changePage(24));
                }}
              >
                {open == false ? (
                  <Tooltip title="Discovery - BD">
                    <CardTravelIcon
                      style={
                        state.selectedComponent === 24
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <CardTravelIcon
                    style={
                      state.selectedComponent === 24
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Discovery - BD"
                      style={
                        state.selectedComponent === 24
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => {
              handleClickMapping();
            }}
          >
            {open == false ? (
              <Tooltip title="Mapeamento">
                <ListItemIcon>
                  <AccountTreeIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <AccountTreeIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText primary="Mapeamento" style={{ color: "white" }} />
            )}
            {list.mapping ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.mapping} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={() => {
                  setState({ selectedComponent: 5 });
                  dispatch(changePage(5));
                }}
              >
                {open == false ? (
                  <Tooltip title="Processos">
                    <SettingsIcon
                      style={
                        state.selectedComponent === 5
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <SettingsIcon
                    style={
                      state.selectedComponent === 5
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Processos"
                      style={
                        state.selectedComponent === 5
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={() => {
                  setState({ selectedComponent: 3 });
                  dispatch(changePage(3));
                }}
              >
                {open == false ? (
                  <Tooltip title="Questionários">
                    <Forum
                      style={
                        state.selectedComponent === 3
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <Forum
                    style={
                      state.selectedComponent === 3
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Questionários"
                      style={
                        state.selectedComponent === 3
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={() => {
                  setState({ selectedComponent: 10 });
                  dispatch(changePage(10));
                }}
              >
                {open == false ? (
                  <Tooltip title="Teste LIA">
                    <Forum
                      style={
                        state.selectedComponent === 10
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <Forum
                    style={
                      state.selectedComponent === 10
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Teste LIA"
                      style={
                        state.selectedComponent === 10
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => {
              handleClickComunication();
            }}
          >
            {open == false ? (
              <Tooltip title="Canais de Comunicação">
                <ListItemIcon>
                  <SupervisedUserCircleIcon style={{ color: "white" }} />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>
                <SupervisedUserCircleIcon style={{ color: "white" }} />
              </ListItemIcon>
            )}

            {open == true && (
              <ListItemText
                primary="Canais de Comunicação"
                style={{ color: "white" }}
              />
            )}
            {list.comunicattion ? (
              <ExpandLess style={{ color: "white" }} />
            ) : (
              <ExpandMore style={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse in={list.comunicattion} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 16 });
                  dispatch(changePage(16));
                }}
              >
                {open == false ? (
                  <Tooltip title="Canal de solicitações">
                    <LibraryBooksIcon
                      style={
                        state.selectedComponent === 16
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <LibraryBooksIcon
                    style={
                      state.selectedComponent === 16
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Canal de solicitações"
                      style={
                        state.selectedComponent === 16
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 20 });
                  dispatch(changePage(20));
                }}
              >
                {open == false ? (
                  <Tooltip title="Comunicação de incidentes">
                    <CardTravelIcon
                      style={
                        state.selectedComponent === 20
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <CardTravelIcon
                    style={
                      state.selectedComponent === 20
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Comunicação de incidentes"
                      style={
                        state.selectedComponent === 20
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setState({ selectedComponent: 42 });
                  dispatch(changePage(42));
                }}
              >
                {open == false ? (
                  <Tooltip title="Canal de denúncia">
                    <GavelIcon
                      style={
                        state.selectedComponent === 42
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    />
                  </Tooltip>
                ) : (
                  <GavelIcon
                    style={
                      state.selectedComponent === 42
                        ? { color: Theme.primary_color }
                        : { color: "white" }
                    }
                  />
                )}

                <div style={{ marginLeft: "5%" }}>
                  {open == true && (
                    <ListItemText
                      primary="Canal de denúncia"
                      style={
                        state.selectedComponent === 42
                          ? { color: Theme.primary_color }
                          : { color: "white" }
                      }
                    ></ListItemText>
                  )}
                </div>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </div>
  );
};

export default DrawerComponent;
