import React, { Component } from 'react';

import { Grid, Typography, Card, IconButton, Tooltip } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import EditCompany from './editCompany';

import theme from '../../../../core/theme.json';

import myApp from '../../../../core/infrastructure/firebaseConfig';
import "firebase/compat/firestore";
import { getUserInformation } from '../../../command/getUserInformation';

class HomeTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialog: false,
    }
  }

  handleDialog() {
    this.setState({
      dialog: !this.state.dialog,
    })
  }

  componentDidMount(){
    this.handleGetPhotoURL();
    this.handleGetInfoDPO();
   
  }

  async handleGetPhotoURL() {
    let db = await myApp.firestore().collection('solicitationParameters').where("fk_company_group", "==", localStorage.getItem("currentCompany")).get();
    let userPhoto = db.docs[0].data().logoUrl;
    localStorage.setItem("userPhoto", userPhoto);
  }

  async handleGetInfoDPO() {
    let getInfo = await myApp.firestore().collection('companies').doc(localStorage.getItem('currentCompany')).get();
    let dpo_phone = getInfo.data().dpo_phone;
    localStorage.setItem('dpo_phone', dpo_phone);
    document.title = `BeePrivacy - ${getInfo.data().nome}`
  }

 
  render() {
    return (
      <div>
        <Card style={{padding: "8px", marginBottom: "16px"}}>
          <Grid 
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" component="h5">
                Overview da Empresa
              </Typography>
            </Grid>
            <div style={{flexGrow: 1}}/>
            <Grid item>
              <Tooltip title="Editar">
              <IconButton onClick={() => {this.handleDialog()}}>
                <EditIcon style={{color: theme.primary_color}} />
              </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>

        <EditCompany dialog={this.state.dialog} />
      </div>
    );
  }
}

export default HomeTitle;