import Swal from "sweetalert2";

export default function SaveApurationDenunceHelper({
  sensitive,
  denunce,
  status,
  verificationCheckBox,
  name,
  email,
  cpf,
  company_relation_user,
  verificationConclusation,
  acept,
  setConfimationDialog,
  response,
  setLoadingSend

}) {
  // console.log(sensitive == undefined , denunce.Sensitive == undefined)

  if (sensitive == undefined && denunce.Sensitive == undefined) {
    return Swal.fire({
      icon: "error",
      title: 'Campo "Grau de sensibilidade" não pode ser vazio',
      showConfirmButton: false,
      timer: 1500,
   }) && setLoadingSend(false)
  }

  if (status == "Concluído" && verificationCheckBox == false) {
    return Swal.fire({
      icon: "error",
      title: "Aceite os termos para continuar",
      showConfirmButton: false,
      timer: 1500,
    });
  }
 
  if (status == "Concluído") {
    if (cpf == "") {
   return   Swal.fire({
        icon: "error",
        title: 'Campo "Cpf" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
     
    }

    if (cpf.replace(/\D/gim, "").length < 11) {
    return    Swal.fire({
        icon: "error",
        title: 'Preencha o campo "CPF"',
        showConfirmButton: false,
        timer: 1500,
      });
    
    }
    if (name == "") {
    return  Swal.fire({
        icon: "error",
        title: 'Campo "Nome" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
      
    }

    if (email == "") {
   return   Swal.fire({
        icon: "error",
        title: 'Campo "Email" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
  
    }
    if (cpf == "") {
   return   Swal.fire({
        icon: "error",
        title: 'Campo "Cpf" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
    
    }
    if (
     response == "" &&
     denunce.response == undefined
    ) {
    return  Swal.fire({
        icon: "error",
        title: 'Campo "Resposta" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
  
    }
    if (verificationConclusation == "") {
    return  Swal.fire({
        icon: "error",
        title: "Conclusão de apuração não pode ser vazio",
        showConfirmButton: false,
        timer: 1500,
      });
    
    }
    if (company_relation_user == "") {
     return Swal.fire({
        icon: "error",
        title: 'Campo "Relação com a empresa" não pode ser vazio',
        showConfirmButton: false,
        timer: 1500,
      });
  
    }
    if (status == "Concluído" && acept == false) {

      
      return setConfimationDialog(true)
    }
  }else{
    setLoadingSend(true)
  }

  // this.setState({
  //   loadingSend: true,
  // });
  // this.setState({
  //   handleStatus: "",
  // });
}
