import React, { Component } from "react";
// firebase imports
import myApp from "../../../core/infrastructure/firebaseConfig";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// material imports
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {InputAdornment ,  OutlinedInput, InputLabel} from "@material-ui/core"
// internal imports
import routerHistory from "../../../core/infrastructure/routerHistory";
import { loginListener } from "../../commands/loginListener";
import theme from "../../../core/theme.json";
import "./login.css";
// import loginLogo from "../../../res/images/dashboard_logo.png";
import { getUserInformationById } from "../../../dashboard/command/getUserInformation";
import ForgotPassword from "../../components/forgotPasswordDialog";
import { sendResetPasswordMail } from "../../commands/sendResetMail";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      pass: "",
      errorUser: false,
      errorPass: false,
      errorLogin: false,
      submitButtonDisabled: true,
      errorMessage: "",
      openForgotPasswordDialog: false,
      forgotPasswordEmail: "",
      sendMailButtonDisabled: true,
      errorMail: false,
      loadingDialog: false,
      isvisiblePassword:false,
      loginLogo: 'https://firebasestorage.googleapis.com/v0/b/bluelegal-lgpd-c7c1f.appspot.com/o/files%2FnewLogo.png?alt=media&token=72666d47-155a-4d7f-9e2a-4fa79e548e8a',
    };

    this.handleCloseForgotPasswordDialog =
      this.handleCloseForgotPasswordDialog.bind(this);
    this.handleForgotPasswordEmail = this.handleForgotPasswordEmail.bind(this);
    this.sendResetPasswordEmail = this.sendResetPasswordEmail.bind(this);
  }

  componentDidMount() {
    loginListener();
  }

  handleUser(event) {
    this.setState({
      user: event.target.value,
      errorUser: false,
      errorPass: false,
      errorLogin: false,
    });

    if (event.target.value !== "" && this.state.pass !== "") {
      this.setState({
        submitButtonDisabled: false,
      });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
    }
  }

  handlePass(event) {
    this.setState({
      pass: event.target.value,
      errorUser: false,
      errorPass: false,
      errorLogin: false,
    });

    if (event.target.value !== "" && this.state.user !== "") {
      this.setState({
        submitButtonDisabled: false,
      });
    } else {
      this.setState({
        submitButtonDisabled: true,
      });
    }
  }

  async login() {
    try {
      const userCredential = await myApp
        .auth()
        .signInWithEmailAndPassword(this.state.user, this.state.pass);

      const userData = await getUserInformationById(userCredential.user.uid);

      if (userData.resetPassword) {
        routerHistory.push("/reset-password/" + userData.id);
      } else {
        routerHistory.push("/company");
      }
    } catch (error) {
      console.error(error.message);

      if (error.code === "auth/user-not-found") {
        this.setState({
          errorUser: true,
          errorPass: false,
          errorLogin: false,
          errorMessage: "Usuário não existe",
        });
      } else if (error.code === "auth/wrong-password") {
        this.setState({
          errorUser: false,
          errorPass: true,
          errorLogin: false,
          errorMessage: "Senha incorreta",
        });
      } else if (error.code === "auth/too-many-requests") {
        this.setState({
          errorLogin: true,
          errorMessage:
            "Login temporariamente inativo devido à muitas tentativas incorretas",
        });
      } else {
        this.setState({
          errorLogin: true,
          errorMessage: error.message,
        });
      }
    }
  }

  handleOpenForgotPasswordDialog() {
    this.setState({
      openForgotPasswordDialog: true,
    });
  }

  handleCloseForgotPasswordDialog() {
    this.setState({
      openForgotPasswordDialog: false,
      forgotPasswordEmail: "",
      sendMailButtonDisabled: true,
      errorMail: false,
      errorMailMessage: "",
      loadingDialog: false,
    });
  }

  handleForgotPasswordEmail(event) {
    this.setState({
      forgotPasswordEmail: event.target.value,
      errorMail: false,
      errorMailMessage: "",
    });

    if (event.target.value !== "") {
      this.setState({
        sendMailButtonDisabled: false,
      });
    } else {
      this.setState({
        sendMailButtonDisabled: true,
      });
    }
  }

  async sendResetPasswordEmail() {
    const validaEmail =
      this.state.forgotPasswordEmail !== "" &&
      this.state.forgotPasswordEmail.includes(".") &&
      this.state.forgotPasswordEmail.includes("@");

    if (validaEmail) {
      const database = myApp.firestore();

      try {
        this.setState({
          loadingDialog: true,
          sendMailButtonDisabled: true,
        });

        const usersSnapshot = await database
          .collection("users")
          .where("email", "==", this.state.forgotPasswordEmail)
          .get();

        if (!usersSnapshot.empty) {
          const user = {
            ...usersSnapshot.docs[0].data(),
            id: usersSnapshot.docs[0].id,
          };

          await database.collection("users").doc(user.id).update({
            resetPassword: true,
          });

          await sendResetPasswordMail(user);

          this.handleCloseForgotPasswordDialog();
        } else {
          this.setState({
            errorMail: true,
            errorMailMessage: "E-mail não existe",
            loadingDialog: false,
            sendMailButtonDisabled: false,
          });
        }
      } catch (error) {
        console.error("Error sending mail to reset password: ", error);
      }
    } else {
      this.setState({
        errorMail: true,
        errorMailMessage: "Digite um e-mail válido",
      });
    }
  }

  render() {
    return (
      <div>
        <Grid container component="main" className="root">
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className="image" />
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              style={{ width: "60%" }}
            >
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Grid item>
                  <img alt="logo" src={this.state.loginLogo} style={{ width: 400 }} />
                </Grid>
              </Grid>
              <div style={{ height: "38px" }} />
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      color: theme.tertiary_color,
                      textJustify: "center",
                    }}
                  >
                    Respeito e tratamento adequado
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{
                      color: theme.tertiary_color,
                      textJustify: "center",
                    }}
                  >
                    aos dados pessoais.
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ height: "38px" }} />
              <Grid
                component={Paper}
                elevation={4}
                item
                container
                direction="column"
                style={{ padding: 20, width: "100%" }}
              >
                <Grid item style={{ width: "100%" }}>
                  <Typography variant="h6" component="h6">
                    Login
                  </Typography>
                </Grid>
                <div style={{ height: "25px" }} />
                <Grid item style={{ width: "100%" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Usuário"
                    value={this.state.user}
                    onChange={(e) => this.handleUser(e)}
                    error={this.state.errorLogin || this.state.errorUser}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <div style={{ height: "25px" }} />
                <Grid item style={{ width: "100%" }}>
                  <OutlinedInput
                    variant="outlined"
                    placeholder="Senha"
                    type={this.state.isvisiblePassword == true ?"text":"password"}
                    value={this.state.pass}
                    onChange={(e) => this.handlePass(e)}
                    error={this.state.errorLogin || this.state.errorPass}
                    style={{ width: "100%" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={()=>this.setState({isvisiblePassword:true})}
                          onMouseDown={()=>this.setState({isvisiblePassword:false})}
                        >
                          {this.state.isvisiblePassword == true ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <div style={{ height: "15px" }} />
                <Grid item style={{ width: "100%" }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.handleOpenForgotPasswordDialog();
                    }}
                  >
                    Esqueci minha senha
                  </Link>
                </Grid>
                <div style={{ height: "25px" }} />
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      component="subtitle1"
                      style={{
                        display:
                          this.state.errorLogin ||
                          this.state.errorUser ||
                          this.state.errorPass
                            ? "inherit"
                            : "none",
                        color: theme.warning_color,
                      }}
                    >
                      {this.state.errorMessage}
                    </Typography>
                  </Grid>
                  <Grid style={{ flexGrow: 1 }} />
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{
                        background: this.state.submitButtonDisabled
                          ? theme.opacity
                          : theme.primary_color,
                        color: theme.secondary_color,
                        fontSize: theme.font_button,
                      }}
                      onClick={() => {
                        this.login();
                      }}
                      disabled={this.state.submitButtonDisabled}
                    >
                      Acessar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ForgotPassword
          openForgotPasswordDialog={this.state.openForgotPasswordDialog}
          forgotPasswordEmail={this.state.forgotPasswordEmail}
          sendMailButtonDisabled={this.state.sendMailButtonDisabled}
          errorMail={this.state.errorMail}
          errorMailMessage={this.state.errorMailMessage}
          loadingDialog={this.state.loadingDialog}
          handleCloseForgotPasswordDialog={this.handleCloseForgotPasswordDialog}
          handleForgotPasswordEmail={this.handleForgotPasswordEmail}
          sendResetPasswordEmail={this.sendResetPasswordEmail}
        />
      </div>
    );
  }
}

export default Login;
