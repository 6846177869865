import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../dashboard/presentation/pages/dashboard";
import Company from "../company/presentation/pages/company";
import AnswerPage from "../dashboard/features/quiz/components/answerPage";
import UserControl from "../user/components/UserControl";
import CookiePolicy from "../cookiePolicy/presentation/pages/cookiePolicy";
import PersonalDataManagementAndAccess from "../solicitationChannel/presentation/pages/personalDataManagementAndAccess";
import followUp from "../solicitationChannel/presentation/pages/followUp";
import solicitationChannel from "../solicitationChannel/presentation/pages/solicitationChannel";
import HighLight from "../highlight/presentation/pages/highlight";
import DashboardProcess from "../dashboard/presentation/pages/dashboardProcess";
import SolicitationFormEdit from "../solicitationChannel/presentation/pages/solicitationFormEdit";
import SolicitationFormNew from "../solicitationChannel/presentation/pages/solicitationFormNew";
import Status404 from "../error/presentation/404";
import SolicitationForm from "../solicitationChannel/presentation/pages/solicitationForm";
import Login from "../login/presentation/pages/login";
import EmailVerified from "../solicitationChannel/presentation/pages/emailVerified";
import solicitationFormCompany from "../solicitationChannel/presentation/pages/solicitationFormCompany";
import EpmSteps from "../dashboard/features/epmSteps/components/epmSteps";
import MasterProcessList from "../dashboard/features/epmSteps/components/masterProcessList";
import AnswerPagrModuleVideo from "../dashboard/features/quiz/components/answerPageModuleVideo";
import NewAnswerPage from "../dashboard/features/quiz/components/newAnwerPage";
import denunceFormCompany from "../solicitationChannel/presentation/pages/denunceFormCompany";
import { DenunceSelect } from "../solicitationChannel/presentation/denunceSelect";
import { DenunceView } from "../solicitationChannel/presentation/denunceView";
import { QuestVideo } from "../dashboard/features/videoReports/QuestVideo";
import { PageNotPermition } from "../user/components/page-notPermitions";
import { getUserInformation } from "../dashboard/command/getUserInformation";
import myApp from "../core/infrastructure/firebaseConfig";

function PrivateRoutes() {
  const [userAdmin, setUserAdmin] = useState("");
  const [permitionsList, setPermitionsList] = useState([]);
  useEffect(() => {
    getPermitions();
  }, []);

  const getPermitions = async () => {
    let aux = [];
    const InfoUser = await getUserInformation();
    let profille = await myApp
      .firestore()
      .collection("profiles_company_group")
      .where(
        "fk_company_group",
        "==",
        localStorage.getItem("@blue-legal/companyGroup")
      )
      .get();

    setUserAdmin(InfoUser.isAdmin ? "sim" : "não");
    if (InfoUser?.permitionLevel) {
      localStorage.setItem("userAdmin", userAdmin);
      let getUnicProfile = profille?.docs?.find(
        (item) => item.data().name == InfoUser?.permitionLevel?.name
      );
      aux.push(getUnicProfile.data()?.PagePermition);
      console.log("user", permitionsList);

      setPermitionsList(aux);
    }
  };
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/company" exact component={Company} />
      <Route path="/quiz/:quizId:/processId" component={AnswerPage} />
      <Route path="/newQuiz/:quizId/:processId" component={NewAnswerPage} />
      <Route
        path="/quizModuleVideo/:quizModuleVideoId"
        component={AnswerPagrModuleVideo}
      />

      <Route
        path="/usercontrol"
        component={
          permitionsList[0]?.find(
            (item) => item.page == "Controle de usuários"
          ) || userAdmin == "sim"
            ? UserControl
            : PageNotPermition
        }
      />
      {/* <Route path="/usercontrol" component={UserControl} /> */}
      <Route path="/cookiepolicy" component={CookiePolicy} />
      <Route
        path="/personal-data-management-and-access"
        component={PersonalDataManagementAndAccess}
      />
      <Route path="/follow-up" component={followUp} />
      <Route path="/personal-data" component={solicitationChannel} />
      <Route path="/highlight/:connection/:fileId" component={HighLight} />
      <Route path="/process" component={DashboardProcess} />
      <Route
        path="/solicitation/edit/:solicitationId"
        exact
        component={SolicitationFormEdit}
      />
      <Route exact path="/solicitation/new" component={SolicitationFormNew} />
      <Route
        path="/denunce/client/selectDenunce/:companyGroupIdc"
        component={DenunceSelect}
      />
      <Route
        path="/denunce/ViewDenunce/:companyGroupIdc"
        component={DenunceView}
      />
      <Route
        path="/solicitation/client/:companyGroupId"
        exact
        component={SolicitationForm}
      />
      <Route
        path="/video/Quest/:id/:indexVideo/:companyGroupId"
        exact
        component={QuestVideo}
      />

      <Route
        path="/denunce/client/company/:companyGroupIdc"
        component={denunceFormCompany}
      />

      <Route
        path="/solicitation/client/company/:companyGroupIdc"
        exact
        component={solicitationFormCompany}
      />
      <Route path="/login" component={Login} />
      <Route
        path="/email/verification/:token"
        exact
        component={EmailVerified}
      />
      <Route path="/new-epm-etapas" exact component={EpmSteps} />
      <Route path="/masterProcessList" exact component={MasterProcessList} />
      <Route path="*" exact component={Status404} />
    </Switch>
  );
}

export default PrivateRoutes;
